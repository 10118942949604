import { useEffect, useState, FC, useContext } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  Button,
  ProgressBar,
} from '@cfa/react-components';
import { useLocationStore } from '../../../../stores/locationStore';
import { callEvent } from '../../../../utils/Amplitude/AmplitudeUtilities';
import styled from 'styled-components';
import { useSignout } from '../../../../user/useSignout';
import { UserContext } from '../../../../user/UserContext';
import { UserContextType } from '../../../../@types/user';

const ModalWrapper = styled.div<{ $disabled: boolean }>`
  ${props => props.$disabled && `pointer-events: none; touch-action: none;`}
`;

const SessionTimeoutModal: FC = () => {
  const { setIsSessionTimeout } = useContext(UserContext) as UserContextType;

  const { handleLogout } = useSignout();

  const timeout = 1800000; // 30 min
  const promptBeforeIdle = 60000; //60 seconds

  const [tillLogout, setTillLogout] = useState<number>(100);
  const [showTimedOutModal, setShowTimedOutModal] = useState<boolean>(false);

  const { activeLocation } = useLocationStore();

  const onIdle = () => {
    setIsSessionTimeout(true);
    handleLogout();
  };

  const onPrompt = () => {
    setShowTimedOutModal(true);
    if (activeLocation) {
      callEvent('session timeout prompt', {
        locationNum: activeLocation ?? 'No location selected',
      });
    }
  };

  const { getRemainingTime, reset } = useIdleTimer({
    onIdle,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    if (!showTimedOutModal) return;
    const interval = setInterval(() => {
      setTillLogout(Math.round((getRemainingTime() / promptBeforeIdle) * 100));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [showTimedOutModal]);

  const logout = () => {
    callEvent('clicked logout session timeout modal', {
      locationNum: activeLocation,
    });
    handleLogout();
  };

  const handleCloseModal = async (btnClick?: boolean) => {
    setShowTimedOutModal(false);

    btnClick &&
      callEvent('clicked still here session timeout modal', {
        locationNum: activeLocation ?? 'No location selected',
      });

    setTillLogout(100);
    reset();
  };

  return (
    <Modal
      size='sm'
      show={showTimedOutModal}
      onClose={() => handleCloseModal()}
      disableOverlayClick
      className='align-top with-top-margin'
    >
      <ModalWrapper $disabled={false}>
        <ModalHeader color='secondary'>Session Timeout</ModalHeader>

        <ModalBody>
          <div style={{ marginBottom: '20px' }}>
            <ProgressBar
              aria-label='progress-bar-label'
              color='primary'
              direction='rtl'
              label=''
              value={tillLogout}
            />
          </div>

          <Typography>
            You&apos;re being timed out due to inactivity. Please select an
            option below. Otherwise, you will be logged out automatically.
          </Typography>

          <ModalFooter>
            <div style={{ textAlign: 'center' }}>
              <Button
                size='sm'
                style={{ display: 'inline-block', marginRight: '10px' }}
                variant='outlined'
                onClick={logout}
              >
                Log Out
              </Button>
              <Button
                size='sm'
                style={{ display: 'inline-block' }}
                onClick={() => handleCloseModal(true)}
              >
                I&apos;m still here
              </Button>
            </div>
          </ModalFooter>
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
};

export default SessionTimeoutModal;
