import styled from 'styled-components';
import { themeSizes } from '../../styles/globalStyleVars';

export const ButtonRow = styled.div<{
  rowAlign?: 'center' | 'start' | null;
  noMargin?: boolean;
  customMargin?: string;
  stack?: boolean;
  gap?: string;
  reverseOrder?: boolean;
}>`
  display: flex;
  justify-content: ${props => props.rowAlign ?? 'space-between'};
  column-gap: ${props => (props.rowAlign === 'center' ? '12px' : 'unset')};
  row-gap: ${props => props.gap ?? '12px'};
  align-items: center;
  margin-bottom: ${props =>
    props.noMargin
      ? '0px'
      : props.customMargin
      ? props.customMargin
      : themeSizes.xl};
  ${props =>
    props.stack
      ? 'flex-direction: column'
      : props.reverseOrder
      ? 'flex-direction: row-reverse'
      : 'flex-direction: unset'};
  flex-wrap: wrap;
`;
