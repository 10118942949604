const MINUTE = 60;
const TWO_MINUTES = 120;
const HOUR = MINUTE * 60; // 3600
const ONE_HOUR_CUTOFF = HOUR * 2; // 7200
const DAY = HOUR * 24; // 86400
const DAY_CUTOFF = DAY + 1; //86401
const TWO_DAYS = DAY * 2;
const WEEK = DAY * 7; // 604800
const WEEK_CUTOFF = WEEK + 59; // 604859

export {
  MINUTE,
  TWO_MINUTES,
  HOUR,
  ONE_HOUR_CUTOFF,
  DAY,
  DAY_CUTOFF,
  TWO_DAYS,
  WEEK,
  WEEK_CUTOFF,
};
