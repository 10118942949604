/**
 * Determine the mobile operating system.
 * This function returns one of 'Windows Phone', 'Android', 'iOS', or undefined.
 *
 * @returns {String | undefined}
 */

export const getMobileOS = () => {
  const ua = navigator.userAgent;

  // Windows Phone's UA also contains "Android" so it needs to be the first check
  if (/windows phone/i.test(ua)) {
    return 'Windows Phone';
  }

  if (/android/i.test(ua)) {
    return 'Android';
  }

  if (/iPad|iPhone|iPadPro/i.test(ua)) {
    return 'iOS';
  }

  // Return undefined if a mobile OS is not matched
  return;
};
