import { postAttachment } from '../api/requests/requests';
import { callEvent } from '../Amplitude/AmplitudeUtilities';
import { FileWithPreview } from '../../interfaces/AttachmentInterface';
import { SupportFormInterface } from '../../interfaces/SupportFormInterface';

/**
 * Function that calls postAttachment to add attachments to a ticket
 * @param files the array of files to be submitted
 * @param ticketId the `ID` of the ticket to add files as attachments
 * @param ticketNumber the `Number` of the ticket for analytics
 * @returns a Promise with an array of attachment errors - empty if none
 */
interface FormData {
  formID?: SupportFormInterface['id'];
  formTitle?: SupportFormInterface['title'];
}

export const handleAddAttachments = async (
  files: FileWithPreview[],
  ticketId: string,
  ticketNumber: string,
  formData?: FormData
) => {
  const attachmentErrors: FileWithPreview[] = [];
  await files.reduce(async (a, file) => {
    // Wait for the previous file to finish posting
    await a;
    // POST this file
    try {
      await postAttachment(file, ticketId);
      callEvent('form add attachment', {
        mimeType: file.type,
        'case number': ticketNumber,
        'attachment method': file.attachmentMethod,
        'form id': formData?.formID,
        'form name': formData?.formTitle,
      });
    } catch (error) {
      // file upload failed
      attachmentErrors.push(file);
      console.error(error);
    }
  }, Promise.resolve());
  return attachmentErrors;
};
