import { FC, ReactNode } from 'react';
import { Button, Typography } from '@cfa/react-components';
import { themeSizes } from '../../../styles/globalStyleVars';
import { IconTicket } from '@tabler/icons-react';
import { ChildCaseHeading } from './ChildCaseDrawerContent';
import { AlertsDrawerEmptyState } from './AlertsDrawerEmptyState';

interface Props {
  showChildCaseSuccess?: boolean;
  showSomeErrorsState?: boolean;
  getChildTicketPath?: string;
  backButton: ReactNode;
  closeButton: ReactNode;
  goBackToChildCase?: () => void;
}

export const ChildCasesSuccess: FC<Props> = ({
  showChildCaseSuccess,
  showSomeErrorsState,
  getChildTicketPath,
  backButton,
  closeButton,
  goBackToChildCase,
}) => {
  const ViewTicketButton = () => (
    <Button size='sm' href={getChildTicketPath}>
      View Ticket
    </Button>
  );

  return (
    <>
      <ChildCaseHeading>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {backButton}
          <Typography variant='body2' fontWeight='bold' color='secondary'>
            Return to Alerts and Outages
          </Typography>
        </div>
        <div>{closeButton}</div>
      </ChildCaseHeading>
      {showChildCaseSuccess && (
        <AlertsDrawerEmptyState
          icon={<IconTicket />}
          subheading='Thanks for letting us know your Restaurant is impacted by this
              issue. We’ll send updates as soon as we have them.'
        >
          <ViewTicketButton />
        </AlertsDrawerEmptyState>
      )}
      {showSomeErrorsState && (
        <AlertsDrawerEmptyState
          icon={<IconTicket />}
          description={
            <span>
              There was an issue creating a ticket for all of your Restaurants,
              but we successfully created a ticket for at least one.
              <br />
              <br />
              Please visit Alerts & Outages to try again.
            </span>
          }
        >
          <ViewTicketButton />
          <Button
            variant='outlined'
            size='sm'
            style={{ marginTop: themeSizes.lg }}
            onClick={goBackToChildCase}
          >
            Open Another Ticket
          </Button>
        </AlertsDrawerEmptyState>
      )}
    </>
  );
};
