import { useContext, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { UserContext } from '../../../user/UserContext';
import { UserContextType } from '../../../@types/user';
import {
  ACTIVITY_STREAM_ENTRY,
  AppSyncSocketEvent,
  EventPayloadData,
  UPDATED_ALERTS_OUTAGES,
  UPDATED_CASE_SUMMARY,
} from './subscriptions';
import { v4 as uuidv4 } from 'uuid';

const API_URL = `${import.meta.env.VITE_ESR_WSS_URL}/graphql/realtime?header=`;

export const useSubscription = (
  subscription: string,
  onData: (data: EventPayloadData) => void
) => {
  const { userState } = useContext(UserContext) as UserContextType;

  useEffect(() => {
    if (!userState.base64IdToken) {
      return;
    }

    const webSocket = new WebSocket(
      API_URL + userState.base64IdToken + '&payload=e30=',
      ['graphql-ws']
    );

    const SUBSCRIPTION_ID = uuidv4();

    webSocket.onopen = () => {
      webSocket.send(JSON.stringify({ type: 'connection_init' }));
    };

    webSocket.onmessage = event => {
      const message = JSON.parse(event.data) as AppSyncSocketEvent;
      if (message.type == 'connection_ack') {
        //start_ack can subscribe now
        {
          webSocket.send(
            JSON.stringify({
              id: SUBSCRIPTION_ID,
              payload: {
                data: subscription,
                extensions: {
                  authorization: {
                    Authorization: `${userState.idToken}`,
                    host: import.meta.env.VITE_ESR_BASE_URL,
                  },
                },
              },
              type: 'start',
            })
          );
        }
      }

      if (message.type == 'data' && message.payload?.data) {
        onData(message.payload.data);
      }
    };

    return () => {
      if (webSocket.readyState === 1) {
        webSocket.send(
          JSON.stringify({
            id: SUBSCRIPTION_ID,
            type: 'stop',
          })
        );
        webSocket.close();
      }
    };
  }, [userState.base64IdToken]);
};

export const useTicketDetailsSubscription = (
  id: string,
  activeLocation: string
) => {
  const queryClient = useQueryClient();
  useSubscription(ACTIVITY_STREAM_ENTRY(activeLocation), data => {
    if (!(data.addedActivityStreamEntrySummaryView?.caseId === id)) return;
    // invalidate the query if the caseId matches
    queryClient.invalidateQueries({ queryKey: ['get-ticket', id] });
  });
  useSubscription(UPDATED_CASE_SUMMARY(activeLocation), data => {
    if (!(data.updatedCaseSummaryView?.id === id)) return;
    // invalidate the query if the id matches
    queryClient.invalidateQueries({ queryKey: ['get-ticket', id] });
  });
};

export const useAlertsOutagesSubscription = () => {
  const queryClient = useQueryClient();
  useSubscription(UPDATED_ALERTS_OUTAGES(), () => {
    queryClient.invalidateQueries({ queryKey: ['get-announcements'] });
  });
};
