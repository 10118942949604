import { FC } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Typography,
  Button,
  MessageBlock,
  Row,
} from '@cfa/react-components';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import { ButtonRow } from '../../Styled/ButtonRow';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';
import { useLocationStore } from '../../../stores/locationStore';

const MessageBlockWrapper = styled(Row)`
  justify-content: center;
  padding-bottom: 12px;
  position: absolute;
  left: 0;
  right: 0;
`;

const ErrorMessageBlock = styled(MessageBlock)`
  min-width: 0px;
  width: fit-content;
`;

const PreventModalHeader = styled(ModalHeader)`
  padding-bottom: 21px;
  padding-top: 56px;
  color: ${themeColors.colorGray_6};
`;

const PreventModalTitle = styled(Typography)`
  margin-bottom: ${themeSizes.xl};
  color: ${themeColors.colorGray_8};
`;

interface Props {
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
  title: string;
  subTitle: string | JSX.Element;
  confirmButtonText: string;
  stackedButtons?: boolean;
  reverseOrder?: boolean;
  formName?: string;
  formID?: string;
  hasError?: boolean;
  isLoading?: boolean;
}

const PreventNavigationModal: FC<Props> = ({
  onClose,
  onCancel,
  onConfirm,
  show,
  title,
  subTitle,
  confirmButtonText,
  stackedButtons = true,
  reverseOrder = false,
  formName,
  formID,
  hasError,
  isLoading = false,
}) => {
  const { activeLocation } = useLocationStore();
  return (
    <Modal
      disableOverlayClick
      show={show}
      onClose={onClose}
      className='align-top with-top-margin'
    >
      {hasError && (
        <MessageBlockWrapper>
          <ErrorMessageBlock
            severity='error'
            title='An error occurred. Please try again.'
          >
            <></>
          </ErrorMessageBlock>
        </MessageBlockWrapper>
      )}
      <PreventModalHeader variant='h3'>{title}</PreventModalHeader>
      <ModalBody>
        <PreventModalTitle>{subTitle}</PreventModalTitle>
        <ButtonRow
          rowAlign='center'
          stack={stackedButtons}
          reverseOrder={reverseOrder}
        >
          <Button
            onClick={() => {
              onConfirm();
              callEvent('exit form flow', {
                'form name': formName,
                'form id': formID,
                locationNum: activeLocation,
              });
            }}
            size='sm'
            style={{ minWidth: '206px' }}
            loading={isLoading}
          >
            {confirmButtonText}
          </Button>
          <Button
            onClick={onCancel}
            size='sm'
            variant='outlined'
            style={{ minWidth: '206px' }}
          >
            Cancel
          </Button>
        </ButtonRow>
      </ModalBody>
    </Modal>
  );
};

export default PreventNavigationModal;
