import { FC } from 'react';
import IconShadow from '../../assets/celebration-shadow.svg';
import styled from 'styled-components';
import { themeSizes } from '../../styles/globalStyleVars';

const IconShadowImage = styled.img`
  margin-top: ${themeSizes.sm};
  width: 62px;
  height: auto;
  padding: 0;
`;

interface Props {
  tablerIcon?: JSX.Element;
}

export const IconWithShadow: FC<Props> = ({ tablerIcon }) => {
  return (
    <>
      {tablerIcon}
      <IconShadowImage src={IconShadow} alt='Empty State Icon Shadow' />
    </>
  );
};
