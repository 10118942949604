import { create } from 'zustand';

export interface PaginationState {
  ticketPageNumber: number;
  setTicketPageNumber: (pageNumber: number) => void;
}

export const usePaginationStore = create<PaginationState>(set => ({
  ticketPageNumber: 1,
  setTicketPageNumber: (value: number) => set({ ticketPageNumber: value }),
}));
