import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import type { BugsnagErrorBoundary } from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import { UserClaims } from '@okta/okta-auth-js';

export let ErrorBoundary: BugsnagErrorBoundary | undefined;

export const initializeBugsnag = (userInfo: UserClaims) => {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_ID,
    appVersion: __APP_VERSION__,
    plugins: [new BugsnagPluginReact()],
    releaseStage: import.meta.env.VITE_APP_ENVIRONMENT,
    maxBreadcrumbs: 100,
    maxEvents: 100,
    metadata: {
      audience: userInfo.userType,
      locations: userInfo.cfa_perms,
    },
  });

  BugsnagPerformance.start({
    apiKey: import.meta.env.VITE_BUGSNAG_ID,
    appVersion: __APP_VERSION__,
    releaseStage: import.meta.env.VITE_APP_ENVIRONMENT,
    autoInstrumentFullPageLoads: true,
    autoInstrumentRouteChanges: true,
    autoInstrumentNetworkRequests: true,
  });

  Bugsnag.setUser(
    userInfo['cfa-login'] as string,
    userInfo.login as string,
    userInfo.name as string
  );

  ErrorBoundary = Bugsnag?.getPlugin('react')?.createErrorBoundary(React);
};

export const sendBug = (
  error: string,
  severity: 'info' | 'warning' | 'error',
  page: string
) => {
  Bugsnag.notify(new Error(error), function (event) {
    //if (condition to not send bug) return false
    event.severity = severity;
    event.context = page;
  });
};
