import { create } from 'zustand';
import {
  NotificationValues,
  ToastNotification,
} from '../interfaces/NotificationInterface';
export interface NotificationsState {
  notifications: NotificationValues[];
  toastNotification: Nullable<ToastNotification>;
  clearNotifications: () => void;
  addNotification: (args: NotificationValues) => void;
  setLoadingNotification: () => void;
  removeNotification: (id: number) => void;
  setToastNotification: (arg: Nullable<ToastNotification>) => void;
}

export const useNotificationsStore = create<NotificationsState>(set => ({
  notifications: [],
  toastNotification: null,
  clearNotifications: () => set({ notifications: [] }),
  addNotification: (newNotification: NotificationValues) =>
    set(state => {
      return {
        notifications: [
          { ...newNotification, messageId: state.notifications.length + 1 },
        ],
      };
    }),
  removeNotification: (id: number) =>
    set(state => {
      return {
        notifications: state.notifications.filter(_n => _n.messageId !== id),
      };
    }),
  setLoadingNotification: () =>
    set(state => {
      return {
        notifications: [
          {
            severity: 'info',
            type: 'loading',
            messageId: state.notifications.length + 1,
          },
        ],
      };
    }),
  setToastNotification: (arg: Nullable<ToastNotification>) =>
    set({ toastNotification: arg }),
}));
