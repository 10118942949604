import React, { FC, useContext, useEffect, useState, MouseEvent } from 'react';
import styled from 'styled-components';
import {
  IconButton,
  Typography,
  Button,
  useBreakpoints,
  useMediaQuery,
} from '@cfa/react-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import { AlertsDrawerEmptyState } from './AlertsDrawerEmptyState';
import { IconChevronLeft, IconLockAccess, IconX } from '@tabler/icons-react';
import { UserContext } from '../../../user/UserContext';
import { UserContextType } from '../../../@types/user';
import { AlertItem } from './AlertItem';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';
import { useLocationStore } from '../../../stores/locationStore';
import { useAlertsStore } from '../../../stores/alertsStore';
import { ChildCaseCheckbox } from './ChildCaseCheckbox';
import { useGetMinorCases } from '../../../utils/api/requests/useQueries';
import { ChildCasesSuccess } from './ChildCasesSuccess';
import { ChildCaseTicketLink } from './ChildCaseTicketLink';
import { LoadingOverlay } from './LoadingOverlay';

interface Props {
  closeAlertsDrawer: () => void;
  goBack: () => void;
  alertChildCaseOpen: AlertInterface;
}

export type SettledTicket = {
  id: string | undefined;
  locationNum: string;
  error: boolean;
};

export const ChildCaseHeading = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    padding: 0;
  }

  svg {
    height: 24px !important;
    width: 24px !important;
  }

  h3,
  h4,
  svg {
    color: ${themeColors.secondaryBlue};
  }
`;

const ChildCaseWrapper = styled.div`
  margin-top: ${themeSizes.xxl};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  padding-right: 20px;
`;

const ChildCaseForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SubmitButtonWrapper = styled.div`
  padding-bottom: ${themeSizes.xl};
  padding-top ${themeSizes.sm};
  width: 100%;
  background-color: ${themeColors.primaryWhite};
  position: absolute;
  bottom: 0;
  right: ${themeSizes.lg};

  > button {
    float: right;
  }
`;

const LocationSelectionWrapper = styled.div`
  margin-bottom: ${themeSizes.xs};
  padding-bottom: 72px;

  > p {
    margin-bottom: ${themeSizes.md};
    color: ${themeColors.colorBlack};
  }

  > div {
    padding-top: ${themeSizes.xs};
    margin-bottom: 20px;
  }
`;

export const ChildCaseDrawerContent: FC<Props> = ({
  closeAlertsDrawer,
  goBack,
  alertChildCaseOpen,
}) => {
  const breakpoints = useBreakpoints();
  const isExtraSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const { userState } = useContext(UserContext) as UserContextType;
  const { userLocations } = useLocationStore();
  const { setAlertDismissed } = useAlertsStore();

  const {
    data: minorCases,
    isLoading: minorCasesLoading,
    isError: minorCasesError,
    refetch: refetchMinorCases,
  } = useGetMinorCases(alertChildCaseOpen.majorCaseId as string);
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const [settledChildTickets, setSettledChildTickets] = useState<
    SettledTicket[]
  >([]);
  const [showSomeCasesSuccess, setShowSomeCasesSuccess] =
    useState<boolean>(false);
  const [showAllCasesFailed, setShowAllCasesFailed] = useState<boolean>(false);
  const [childCasesLoading, setChildCasesLoading] = useState<boolean>(false);
  const [showChildCaseSuccess, setShowChildCaseSuccess] =
    useState<boolean>(false);
  const [firstChildTicketPath, setFirstChildTicketPath] = useState<
    string | undefined
  >();

  userLocations.sort((a, b) => a.name.localeCompare(b.name));
  const locationsNoUpdates = minorCases
    ? userLocations.filter(
        x => !minorCases?.some(item => item.locationNum === x.number)
      )
    : userLocations;

  const locationsWithUpdates = userLocations.filter(x =>
    minorCases?.some(item => item.locationNum === x.number)
  );

  const getTicketPath = (locationNum: string, id: string) =>
    `/tickets/${locationNum}/${id}`;

  const getChildTicketPath = () => {
    const firstValid = settledChildTickets.find(
      ticket => ticket.id !== undefined && !ticket.error
    );
    if (firstValid) {
      return getTicketPath(firstValid.locationNum, firstValid.id as string);
    }
    return;
  };

  const getExistingTicketPath = (locationNumber: string) => {
    const caseId = minorCases?.find(x => x.locationNum === locationNumber)?.id;
    return getTicketPath(locationNumber, caseId as string);
  };

  useEffect(() => {
    if (
      settledChildTickets.length > 0 &&
      settledChildTickets.length === selectedLocations.length
    ) {
      setChildCasesLoading(false);
      const firstSuccessPath = getChildTicketPath();
      setFirstChildTicketPath(firstSuccessPath);
      const childCaseError = settledChildTickets.some(
        ticket => ticket.error === true
      );
      if (!childCaseError && settledChildTickets.length >= 1) {
        setShowChildCaseSuccess(true);
      } else {
        if (firstSuccessPath) {
          setShowSomeCasesSuccess(true);
        } else {
          setSettledChildTickets([]);
          setShowAllCasesFailed(true);
        }
      }
    }
  }, [settledChildTickets]);

  useEffect(() => {
    setAlertDismissed(alertChildCaseOpen);
    if (alertChildCaseOpen) {
      callEvent('view this affects my restaurant', {
        'alert id': alertChildCaseOpen.id,
        id: alertChildCaseOpen.majorCaseId as string,
        title: alertChildCaseOpen.title,
      });
    }
  }, []);

  const handleCheck = (
    e: React.FormEvent<HTMLInputElement>,
    locationNumber: string
  ) => {
    if (e.currentTarget.checked) {
      setSelectedLocations(prev => [...prev, locationNumber]);
    } else {
      setSelectedLocations(prev => prev.filter(loc => loc !== locationNumber));
    }
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setChildCasesLoading(true);

    callEvent('submit this affects my restaurant', {
      'alert id': alertChildCaseOpen.id,
      id: alertChildCaseOpen.majorCaseId as string,
      'restaurant number': selectedLocations,
      title: alertChildCaseOpen.title,
    });
  };

  const handleErrorRefetch = () => {
    if (minorCasesError) {
      refetchMinorCases();
      return;
    }
    setChildCasesLoading(true);
  };

  const BackButton = () => (
    <IconButton onClick={() => goBack()} style={{ marginRight: themeSizes.sm }}>
      <IconChevronLeft />
    </IconButton>
  );

  const CloseButton = () => (
    <IconButton
      onClick={() => closeAlertsDrawer()}
      style={{ marginLeft: themeSizes.sm }}
    >
      <IconX />
    </IconButton>
  );

  return (
    <div style={{ height: '100%' }}>
      {showChildCaseSuccess || showSomeCasesSuccess ? (
        <ChildCasesSuccess
          showChildCaseSuccess={showChildCaseSuccess}
          showSomeErrorsState={showSomeCasesSuccess}
          goBackToChildCase={() => {
            setShowSomeCasesSuccess(false);
            setSettledChildTickets([]);
            setSelectedLocations([]);
            setFirstChildTicketPath(undefined);
          }}
          getChildTicketPath={firstChildTicketPath}
          backButton={BackButton()}
          closeButton={CloseButton()}
        />
      ) : (
        <>
          <ChildCaseHeading>
            <BackButton />
            <Typography variant={isExtraSmallScreen ? 'h4' : 'h3'}>
              This Affects my Restaurant
            </Typography>
            <CloseButton />
          </ChildCaseHeading>
          {userState.isStaffOrContractor ? (
            <AlertsDrawerEmptyState
              icon={<IconLockAccess />}
              heading='Only Available to Restaurants'
              description='Sorry, this feature is only available to restaurant users.'
            />
          ) : (
            <ChildCaseWrapper>
              <>
                <ChildCaseForm>
                  <ScrollContainer>
                    <Typography
                      variant='h3'
                      style={{ color: themeColors.colorBlack }}
                    >
                      Stay informed for the issue as updates come in.
                    </Typography>
                    <Typography
                      style={{
                        margin: `${themeSizes.md} 0 ${themeSizes.lg} 0`,
                        color: `${themeColors.colorBlack}`,
                      }}
                    >
                      We&apos;ll create a ticket for your Restaurant and send
                      any updates on this issue automatically.
                    </Typography>
                    <AlertItem
                      alertsDrawerOpen={true}
                      alert={alertChildCaseOpen}
                    />
                    <LocationSelectionWrapper>
                      {locationsNoUpdates.length > 0 && (
                        <>
                          <Typography fontWeight='medium'>
                            Which Restaurants are affected?
                          </Typography>

                          {locationsNoUpdates.map(location => (
                            <ChildCaseCheckbox
                              key={location.id}
                              location={location}
                              onChange={e => handleCheck(e, location.number)}
                              submitData={
                                selectedLocations.some(
                                  locNum => locNum === location.number
                                ) && childCasesLoading
                              }
                              majorCaseId={
                                alertChildCaseOpen.majorCaseId as string
                              }
                              userGuid={userState.userDetails['cfa-guid']}
                              settledChildTickets={settledChildTickets}
                              setSettledChildTickets={setSettledChildTickets}
                            />
                          ))}
                        </>
                      )}
                      {locationsWithUpdates.length > 0 && (
                        <>
                          <Typography fontWeight='medium'>
                            Restaurants signed up for updates.
                          </Typography>
                          <div
                            style={{
                              margin: `${themeSizes.xs} 0 ${themeSizes.xs} ${themeSizes.xs}`,
                            }}
                          >
                            {locationsWithUpdates.map(location => (
                              <ChildCaseTicketLink
                                key={location.id}
                                location={location}
                                ticketPath={getExistingTicketPath(
                                  location.number
                                )}
                              />
                            ))}
                          </div>
                        </>
                      )}
                    </LocationSelectionWrapper>
                  </ScrollContainer>
                  {locationsNoUpdates.length !== 0 && (
                    <SubmitButtonWrapper>
                      <Button
                        disabled={selectedLocations.length === 0}
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                          handleSubmit(e);
                        }}
                        size='sm'
                      >
                        Submit
                      </Button>
                    </SubmitButtonWrapper>
                  )}
                </ChildCaseForm>
                <LoadingOverlay
                  childCasesLoading={childCasesLoading}
                  loadingError={minorCasesError || showAllCasesFailed}
                  minorCasesLoading={minorCasesLoading}
                  handleErrorRefetch={() => handleErrorRefetch()}
                />
              </>
            </ChildCaseWrapper>
          )}
        </>
      )}
    </div>
  );
};
