import styled from 'styled-components';
import { Typography } from '@cfa/react-components';
import { themeColors } from '../../styles/globalStyleVars';

export const TypographyLineLimit = styled(Typography)<{
  $lineLimit?: number;
  $fontColor: themeColors;
  $action?: boolean;
}>`
  color: ${props => props.$fontColor};
  overflow: hidden;
  display: -webkit-box;
  ${props =>
    props.$lineLimit &&
    `-webkit-line-clamp: ${props.$lineLimit}`}; /* number of lines to show */
  ${props => props.$lineLimit && `line-clamp: ${props.$lineLimit}`};

  -webkit-box-orient: ${props => (props.$action ? 'horizontal' : 'vertical')};
`;
