import type { PropsWithChildren } from 'react';
import { FallbackComponentProps } from '../../interfaces/BugsnagInterface';
import { ErrorBoundary } from './BugSnagUtilities';

export interface BugsnagErrorBoundaryProps extends PropsWithChildren {
  //Fallback component to be rendered when an error occurs
  FallbackComponent: (props: FallbackComponentProps) => JSX.Element;
}

export const BugsnagErrorBoundary = ({
  children,
  FallbackComponent,
}: BugsnagErrorBoundaryProps) => {
  if (ErrorBoundary) {
    return (
      <ErrorBoundary
        onError={event => (event.unhandled = true)}
        FallbackComponent={props => <FallbackComponent {...props} />}
      >
        {children}
      </ErrorBoundary>
    );
  }
  return <>{children}</>;
};
