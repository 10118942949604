import { FC, useLayoutEffect, lazy, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useGetTicketLocation } from '../../utils/api/requests/useQueries';
import { Container, LoadingIndicator } from '@cfa/react-components';
import { useLocationStore } from '../../stores/locationStore';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const FetchError = lazy(() => import('../../components/Global/FetchError'));

const TicketDetailsNoLocation: FC = () => {
  const { setActiveLocation } = useLocationStore();
  const history = useHistory();
  const [ticketNotFound, setTicketNotFound] = useState(false);

  const { id }: { id: string } = useParams();
  const {
    data: ticket,
    error,
    isLoading,
    isSuccess,
    refetch: refetchTicketLocation,
  } = useGetTicketLocation(id);
  useDocumentTitle('Ticket Details');

  useLayoutEffect(() => {
    if (!isSuccess) return;
    if (ticket) {
      setActiveLocation(ticket.locationNum);
      history.push(`/tickets/${ticket.locationNum}/${id}`);
    } else {
      setTicketNotFound(true);
    }
  }, [isSuccess]);

  return (
    <>
      <Container className='container noPadding'>
        <>
          {isLoading && (
            <LoadingIndicator
              size='md'
              variant='page'
              style={{ margin: '3rem auto' }}
            />
          )}
          {error && (
            <FetchError
              btnClick={refetchTicketLocation}
              errorType='fetch'
              page='ticketDetails'
            />
          )}
          {ticketNotFound && (
            <FetchError
              btnClick={() => history.push('/tickets')}
              errorType='ticketNotFound'
              page='ticketDetails'
              btnPrimary
            />
          )}
        </>
      </Container>
    </>
  );
};
export default TicketDetailsNoLocation;
