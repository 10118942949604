import { FC, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute, LoginCallback } from '@okta/okta-react';
import { Page } from './Page';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TicketDetailsNoLocation from '../pages/Tickets/TicketDetailsNoLocation';

// Lazy Loaded Routes
const Home = lazy(() => import('../pages/Home/Home'));
const Tickets = lazy(() => import('../pages/Tickets/Tickets'));
const TicketDetails = lazy(() => import('../pages/Tickets/TicketDetails'));
const FormsOverview = lazy(() => import('../pages/Forms/FormsOverview'));
const FormDetails = lazy(() => import('../pages/Forms/FormDetails'));
const Search = lazy(() => import('../pages/Search/Search'));
const KnowledgeArticleDetails = lazy(
  () => import('../pages/Knowledge/KnowledgeArticleDetails')
);
const AssetFormExamples = lazy(
  () => import('../pages/Assets/AssetFormExamples')
);
const AppointmentsManagement = lazy(
  () => import('../pages/Appointments/AppointmentsManagement')
);
const AppointmentDetails = lazy(
  () => import('../pages/Appointments/AppointmentDetails')
);
const EquipmentOverview = lazy(
  () => import('../pages/Equipment/EquipmentOverview')
);
const EquipmentBrowse = lazy(
  () => import('../pages/Equipment/EquipmentBrowse')
);
const EquipmentDetails = lazy(
  () => import('../pages/Equipment/EquipmentDetails')
);
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));
const Profile = lazy(() => import('../pages/Profile/Profile'));

export const Routes: FC = () => {
  const { ['equipment-tab']: equipmentTab } = useFlags();

  return (
    <Switch>
      <SecureRoute path='/' exact>
        <Page component={<Home />} />
      </SecureRoute>
      <SecureRoute path='/ ' exact>
        <Page component={<Home />} />
      </SecureRoute>
      <SecureRoute path='/home' exact>
        <Page component={<Home />} />
      </SecureRoute>
      <SecureRoute path={`/tickets/:location?`} exact>
        <Page component={<Tickets />} />
      </SecureRoute>
      <SecureRoute path='/ticket/:id'>
        <Page component={<TicketDetailsNoLocation />} />
      </SecureRoute>
      <SecureRoute path='/tickets/:location/:id'>
        <Page component={<TicketDetails />} />
      </SecureRoute>
      <SecureRoute path='/forms' exact>
        <Page component={<FormsOverview />} />
      </SecureRoute>
      <SecureRoute path='/forms/:id'>
        <Page component={<FormDetails />} />
      </SecureRoute>
      <SecureRoute path='/kb/:id' exact>
        <Page component={<KnowledgeArticleDetails />} />
      </SecureRoute>
      <SecureRoute path='/search' exact>
        <Page component={<Search />} />
      </SecureRoute>
      <SecureRoute path='/asset-form-examples' exact>
        <Page component={<AssetFormExamples />} />
      </SecureRoute>
      <SecureRoute path='/appointments' exact>
        <Page component={<AppointmentsManagement />} />
      </SecureRoute>
      <SecureRoute path='/appointments/:id' exact>
        <Page component={<AppointmentDetails />} />
      </SecureRoute>
      <SecureRoute path='/equipment/:location?' exact>
        <Page
          component={equipmentTab && <EquipmentOverview />}
          featureFlag={equipmentTab}
        />
      </SecureRoute>
      <SecureRoute path='/equipment/:location/:category' exact>
        <Page
          component={equipmentTab && <EquipmentBrowse />}
          featureFlag={equipmentTab}
        />
      </SecureRoute>
      <SecureRoute path='/equipment/:location/:category/:item'>
        <Page
          component={equipmentTab && <EquipmentDetails />}
          featureFlag={equipmentTab}
        />
      </SecureRoute>
      <SecureRoute path='/settings' exact>
        <Page component={<Profile />} />
      </SecureRoute>
      <Route path='/implicit/callback'>
        <LoginCallback />
      </Route>
      <Route path='/*' component={PageNotFound} />
    </Switch>
  );
};
