import { FC, useState, useRef, useEffect } from 'react';
import {
  Typography,
  Button,
  useMediaQuery,
  useBreakpoints,
} from '@cfa/react-components';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import RelativeTime from '../../RelativeTime/RelativeTime';
import { AffectsMeButton } from './AffectsMeButton';
import { TypographyLineLimit } from '../../Styled/TypographyElements';
import { IconAlertTriangleFilled } from '@tabler/icons-react';

export interface Props {
  alert: AlertInterface;
  setAlertsDrawerOpen: () => void;
  setAlertChildCaseOpen: (val: AlertInterface) => void;
}

export interface SeeMoreProps {
  id: string;
  title: string;
}

const AlertWrapper = styled.div`
  display: flex;
  margin: ${themeSizes.sm} 0 ${themeSizes.xs} 0;
`;

const WarningIcon = styled(IconAlertTriangleFilled)`
  align-self: flex-start;
  margin-right: ${themeSizes.sm};
  color: ${themeColors.errorColor};
  flex-shrink: 0;
  width: ${themeSizes.lg};
  height: auto;
`;

const AlertContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${themeSizes.sm};
`;

const AlertHeadingRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SummaryText = styled(Typography)<{ $hasOverflow: boolean }>`
  color: ${themeColors.colorBlack};
  padding: 0;
  white-space: nowrap;

  ${props =>
    props.$hasOverflow &&
    `overflow: hidden;
    text-overflow: ellipsis;
    `};
`;

const SeeMoreSummaryButton = styled(Button)`
  padding: 0;
  width: auto !important;
  min-width: auto !important;
  flex-shrink: 0;
  margin-left: 2px;
  height: ${themeSizes.lg} !important;
`;

const AlertTimeWrapper = styled(Typography)`
  margin-left: ${themeSizes.sm};
  display: flex;
  flex-direction: column;
  text-align: right;
  flex-shrink: 0;
  justify-content: space-between;
`;

const AlertButtonWrapper = styled.div`
  margin-top: ${themeSizes.sm};
  margin-left: ${themeSizes.xl};
  flex-shrink: 0;
`;

export const AlertsBannerItem: FC<Props> = ({
  alert,
  setAlertsDrawerOpen,
  setAlertChildCaseOpen,
}) => {
  const breakpoints = useBreakpoints();
  const isExtraSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(breakpoints.down('md'));
  const widthRef = useRef<HTMLDivElement>(null);
  const summaryRef = useRef<HTMLParagraphElement>(null);
  const [summaryOverflow, setSummaryOverflow] = useState<boolean>(false);

  const setOverflow = (summaryWidth?: number) => {
    const maxSummaryWidth = widthRef.current?.clientWidth;
    if (!summaryWidth || !maxSummaryWidth) return;
    setSummaryOverflow(maxSummaryWidth < summaryWidth);
  };

  const handleWindowSizeChange = (summaryWidth: number | undefined) => {
    setOverflow(summaryWidth);
  };

  useEffect(() => {
    if (isExtraSmallScreen) {
      return setSummaryOverflow(false);
    }
    const summaryWidth = summaryRef.current?.clientWidth;
    setOverflow(summaryWidth);
    window.addEventListener('resize', () =>
      handleWindowSizeChange(summaryWidth)
    );
    return () => {
      window.removeEventListener('resize', () =>
        handleWindowSizeChange(summaryWidth)
      );
    };
  }, [isExtraSmallScreen]);

  const handleSeeMoreClick = (id: string, title: string) => {
    setAlertsDrawerOpen();
    callEvent('see more alert', {
      'alert id': id,
      title: title,
    });
  };

  const SeeMoreButton: FC<SeeMoreProps> = ({ id, title }) => (
    <SeeMoreSummaryButton
      variant='text'
      color='secondary'
      size={isExtraSmallScreen ? 'lg' : 'sm'}
      onClick={() => handleSeeMoreClick(id, title)}
    >
      See More
    </SeeMoreSummaryButton>
  );

  const openChildCase = (alert: AlertInterface) => {
    setAlertsDrawerOpen();
    setAlertChildCaseOpen(alert);
  };

  const handleAffectsMeClick = (alert: AlertInterface) => {
    openChildCase(alert);
    callEvent('select this affects my restaurant', {
      'alert id': alert.id,
      id: alert.majorCaseId as string,
      location: 'banner',
    });
  };

  return (
    <div id='banner-wrapper'>
      <AlertWrapper>
        <WarningIcon />
        <AlertContentWrapper>
          <AlertHeadingRow>
            <TypographyLineLimit
              fontWeight='bold'
              $fontColor={themeColors.colorBlack}
              $lineLimit={isExtraSmallScreen ? 3 : 2}
              style={{
                maxHeight: isExtraSmallScreen ? '72px' : themeSizes.xxl,
              }}
            >
              {alert.title}
            </TypographyLineLimit>
          </AlertHeadingRow>
          {!isExtraSmallScreen && (
            <SummaryWrapper ref={widthRef}>
              <SummaryText
                $hasOverflow={summaryOverflow}
                ref={summaryRef}
                variant='body2'
                style={{ color: themeColors.colorBlack, padding: 0 }}
              >
                {alert.summary}
              </SummaryText>
              {summaryOverflow && (
                <SeeMoreButton id={alert.id} title={alert.title} />
              )}
            </SummaryWrapper>
          )}
        </AlertContentWrapper>
        <AlertTimeWrapper>
          {!isSmallScreen && 'Updated: '}
          <RelativeTime
            date={alert.updatedOn}
            constrainSize={isSmallScreen ? 'medium' : undefined}
          />
          {isExtraSmallScreen && (
            <SeeMoreButton id={alert.id} title={alert.title} />
          )}
        </AlertTimeWrapper>
      </AlertWrapper>
      {alert.majorCaseId && (
        <AlertButtonWrapper>
          <AffectsMeButton onClick={() => handleAffectsMeClick(alert)} />
        </AlertButtonWrapper>
      )}
    </div>
  );
};
