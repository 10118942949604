import { OktaToken } from '../../interfaces/OktaToken';

const getOktaTokens = (): {
  accessToken: Nullable<string>;
  idToken: Nullable<string>;
} => {
  const oktaCookie: Nullable<string> =
    localStorage.getItem('okta-token-storage');
  let accessToken = null,
    idToken = null;

  if (oktaCookie) {
    const token: OktaToken = JSON.parse(oktaCookie);
    accessToken = token.accessToken.accessToken;
    idToken = token.idToken.idToken;
  }

  return { accessToken, idToken };
};

export { getOktaTokens };
