import { useRef, useEffect } from 'react';

const useDocumentTitle = (title: string, remainOnUnmount = false) => {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${import.meta.env.VITE_APP_NAME} - ${title}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!remainOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    []
  );
};

export default useDocumentTitle;
