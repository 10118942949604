import { track } from '@amplitude/analytics-browser';
import { AmplitudeEventProperties } from '../../interfaces/AmplitudeEventProperties';

const callEvent = (
  event: string,
  eventProperties: AmplitudeEventProperties
) => {
  track(event, eventProperties);
};

export { callEvent };
