import { FC, ReactNode } from 'react';
import { Typography, LoadingIndicator } from '@cfa/react-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import styled from 'styled-components';
import IconShadow from '../../../assets/celebration-shadow.svg';

interface Props {
  showLoadingSpinner?: boolean;
  heading?: string;
  subheading?: string;
  description?: string | ReactNode;
  icon?: ReactNode;
  children?: ReactNode;
}

const AlertsEmptyState = styled.div<{ $showLoadingSpinner: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: ${props => (!props.$showLoadingSpinner ? '84px 0' : '142px 0 0')};

  > div {
    display: flex;
    flex-direction: column;
    margin-bottom: ${themeSizes.md};
    align-items: center;
  }

  svg {
    height: ${themeSizes.xl};
    width: ${themeSizes.xl};
    color: ${themeColors.colorGray_6};
  }
`;

const IconShadowImage = styled.img`
  margin-top: ${themeSizes.sm};
  width: 62px;
  height: auto;
  padding: 0;
`;

export const AlertsDrawerEmptyState: FC<Props> = ({
  showLoadingSpinner = false,
  heading,
  subheading,
  description,
  icon,
  children,
}) => (
  <AlertsEmptyState $showLoadingSpinner={showLoadingSpinner}>
    {showLoadingSpinner ? (
      <LoadingIndicator variant='page' size='sm' />
    ) : (
      icon && (
        <div>
          {icon}
          <IconShadowImage src={IconShadow} />
        </div>
      )
    )}
    <Typography
      variant={showLoadingSpinner ? 'h3' : 'h4'}
      style={{ color: themeColors.colorGray_6 }}
    >
      {heading}
    </Typography>
    {subheading && (
      <Typography fontWeight='medium' style={{ marginBottom: themeSizes.xl }}>
        {subheading}
      </Typography>
    )}
    {description && (
      <Typography
        style={{
          marginTop: themeSizes.sm,
          marginBottom: themeSizes.xl,
        }}
      >
        {description}
      </Typography>
    )}
    {children}
  </AlertsEmptyState>
);
