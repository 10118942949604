import { FC } from 'react';
import styled from 'styled-components';
import { Typography, Link } from '@cfa/react-components';
import { themeSizes } from '../../../styles/globalStyleVars';
import { Location } from '../../../interfaces/LocationInterface';
import { IconChevronRight } from '@tabler/icons-react';

interface Props {
  location: Location;
  ticketPath: string;
}

const LocationWithUpdate = styled.div`
  margin-bottom: ${themeSizes.lg};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

   a {
    margin-right: ${themeSizes.md}
    display: flex;
    align-self: center;

    svg {
      margin-left: ${themeSizes.sm};
    }
  }
`;

export const ChildCaseTicketLink: FC<Props> = ({ location, ticketPath }) => (
  <LocationWithUpdate key={location.id}>
    <div>
      <Typography fontWeight='medium' style={{ marginBottom: themeSizes.xs }}>
        {location.name}
      </Typography>
      <Typography variant='body2'>{location.number}</Typography>
    </div>
    <Link
      variant='standalone'
      size='body2'
      href={ticketPath}
      style={{ display: 'flex', alignItems: 'center', fontWeight: '700' }}
    >
      See Ticket
      <IconChevronRight />
    </Link>
  </LocationWithUpdate>
);
