import { gql } from 'graphql-request';

export const MARK_TICKET_READ = gql`
  mutation MyMutation($caseID: ID!) {
    markCaseRead(caseId: $caseID)
  }
`;

export const CLOSE_TICKET = gql`
  mutation CloseTicket($caseId: ID!, $comment: String) {
    closeCase(caseId: $caseId, comment: $comment) {
      id
      status
      closedOn
      willCloseOn
      activityStream {
        id
        caseId
        text
        createdBy {
          id
          firstName
          lastName
          guid
          audience
          photoURL
          fullName
        }
        createdOn
        locationNum
        ... on ActivityStreamAttachment {
          downloadURL
          fileName
          mimeType
        }
        ... on ActivityStreamComment {
          translatedText
        }
      }
    }
  }
`;

export const REQUEST_CASE_UPDATE = gql`
  mutation MyMutation($caseId: ID!) {
    requestCaseUpdate(caseId: $caseId) {
      status
      number
      isUnread
      canRequestUpdate
      requestedItem {
        id
        number
      }
      activityStream {
        id
        caseId
        text
        createdBy {
          firstName
          lastName
          guid
        }
        createdOn
        locationNum
        ... on ActivityStreamAttachment {
          downloadURL
          fileName
          mimeType
        }
      }
    }
  }
`;

export const CHANGE_TICKET_CONTACT = gql`
  mutation MyMutation(
    $caseId: ID!
    $contactId: ID!
    $contactGuid: String
    $previousContactGuid: String
    $locationNum: String
  ) {
    changeCaseContact(
      caseId: $caseId
      contactId: $contactId
      contactGuid: $contactGuid
      previousContactGuid: $previousContactGuid
      locationNum: $locationNum
    )
  }
`;

export const CHANGE_TICKET_STATUS = gql`
  mutation MyMutation($status: CaseStatus!, $caseId: ID!, $comment: String) {
    changeCaseStatus(status: $status, caseId: $caseId, comment: $comment) {
      status
      number
      isUnread
      activityStream {
        id
        caseId
        text
        createdBy {
          id
          firstName
          lastName
          guid
          audience
          photoURL
          fullName
        }
        createdOn
        locationNum
        ... on ActivityStreamAttachment {
          downloadURL
          fileName
          mimeType
        }
        ... on ActivityStreamComment {
          translatedText
        }
      }
    }
  }
`;

export const ADD_TICKET_COMMENT = gql`
  mutation MyMutation($comment: String!, $caseId: ID!) {
    addComment(comment: $comment, caseId: $caseId) {
      updatedOn
      activityStream {
        id
        caseId
        text
        createdBy {
          id
          firstName
          lastName
          guid
          audience
          photoURL
          fullName
        }
        createdOn
        locationNum
        ... on ActivityStreamAttachment {
          downloadURL
          fileName
          mimeType
        }
        ... on ActivityStreamComment {
          translatedText
        }
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: ID!, $caseId: ID!) {
    deleteAttachment(id: $id, caseId: $caseId)
  }
`;

export const MARK_LOCATION_VISITED = gql`
  mutation MyMutation($locationNum: String!, $locationName: String!) {
    markRestaurantVisited(
      locationNum: $locationNum
      locationName: $locationName
    )
  }
`;

export const SUBMIT_FORM_TO_CREATE_CASE = gql`
  mutation MyMutation(
    $locationNum: String!
    $formID: ID!
    $variables: [FormVariable]
  ) {
    submitFormToCreateCase(
      locationNum: $locationNum
      formId: $formID
      formVariables: $variables
    ) {
      id
      number
      ... on SubmitFormResponseType {
        confirmationMessages
      }
    }
  }
`;

export const DELETE_RECENT_SEARCH = gql`
  mutation DeleteRecentSearch($userGuid: String!, $contains: [String]!) {
    deleteRecentSearches(userGuid: $userGuid, contains: $contains)
  }
`;

export const SUBMIT_KB_FEEDBACK = gql`
  mutation MyMutation(
    $articleID: ID!
    $positive: Boolean!
    $reasonCode: Int
    $comment: String
  ) {
    submitKBFeedback(
      articleID: $articleID
      positive: $positive
      reasonCode: $reasonCode
      comment: $comment
    )
  }
`;

export const MARK_ASSET_VIEWED = gql`
  mutation MyMutation($input: AssetViewedInput!) {
    markAssetViewed(input: $input)
  }
`;

export const SUBSCRIBE_UNSUBSCRIBE_CASE = gql`
  mutation MyMutation($caseId: ID!, $subscribe: Boolean!) {
    manageCaseSubscription(caseId: $caseId, subscribe: $subscribe)
  }
`;

export const MARK_USER_ONBOARDING = gql`
  mutation MarkUserOnboardingStatus($status: UserOnboardingStatus!) {
    markUserOnboardingStatus(status: $status)
  }
`;

export const CHANGE_NOTIFICATION_PREFERENCE = gql`
  mutation MyMutation($toggleOn: Boolean!, $type: NotificationType) {
    changeNotificationPreference(toggleOn: $toggleOn, type: $type)
  }
`;

export const SUBMIT_CASE_PROMPT = gql`
  mutation MyMutation($input: CasePromptInput!) {
    submitCasePrompt(input: $input) {
      success
    }
  }
`;
