export const ACTIVITY_STREAM_ENTRY = (activeLocation: string) => {
  return `{"query":"subscription MySubscription {\\n  addedActivityStreamEntrySummaryView(locationNum: \\"${activeLocation}\\") {\\n    id\\n    caseId\\n  }\\n}\\n","variables":null}`;
};

export const UPDATED_CASE_SUMMARY = (activeLocation: string) => {
  return `{"query":"subscription MySubscription {\\n  updatedCaseSummaryView(locationNum: \\"${activeLocation}\\", fieldMutated: \\"state\\") {\\n    id\\n  }\\n}\\n","variables":null}`;
};

export const UPDATED_ALERTS_OUTAGES = () => {
  return `{"query":"subscription MySubscription {\\n  addedAnnouncement {\\n    id\\n  url\\n  updatedOn\\n  title\\n  summary\\n  majorCaseId\\n  isUnread\\n  isExpired\\n  hasBeenUpdated\\n }\\n}\\n","variables":null}`;
};

export interface EventPayloadData {
  addedActivityStreamEntrySummaryView?: ActivityStreamEventPayload;
  updatedCaseSummaryView?: UpdatedCaseEventPayload;
}

export interface AppSyncSocketEvent {
  id: string;
  type: string;
  payload?: {
    data?: EventPayloadData;
  };
}

export interface ActivityStreamEventPayload {
  id?: string;
  caseId?: string;
}

export interface UpdatedCaseEventPayload {
  id?: string;
}
