import { FC, useEffect, useState, ReactElement, lazy } from 'react';
import { useHistory } from 'react-router-dom';
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'));

interface Props {
  component?: ReactElement;
  featureFlag?: boolean;
}

export const Page: FC<Props> = ({ component, featureFlag }) => {
  const history = useHistory();
  const [componentTimer, setComponentTimer] = useState<boolean>(false);
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setComponentTimer(true);
    }, 500);
  }, []);

  if (!component) {
    return <>{componentTimer && <PageNotFound featureFlag={featureFlag} />}</>;
  }

  return component;
};
