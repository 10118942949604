import { formatAudience } from '../../user/setAmplitudeUserProps';

// eslint-disable-next-line
// @ts-ignore
const sdk = await window['UA'];

export const registerUser = async (guid: string) => {
  const channelId = await sdk.channel.id();
  //always tries to create in sub-prod. We reset the channel on logout, so no waster channels
  if (import.meta.env.VITE_APP_ENVIRONMENT !== 'prod') {
    await sdk.create().then(() => {
      //associate this channel with a named user
      sdk.contact.identify(guid);
    });
  }

  //create a channel if one doesn't exist and is in prod
  if (!channelId && import.meta.env.VITE_APP_ENVIRONMENT === 'prod') {
    await sdk.create().then(() => {
      //associate this channel with a named user
      sdk.contact.identify(guid);
    });
  }
};

//updates audience attribute for named user
export const updateAudienceAttribute = async (aud: string) => {
  const editor = await sdk.contact.editAttributes();
  editor.set('cfa_audience', formatAudience(aud));
  await editor.apply();
};

//updates location tag for named user
export const updateLocationTag = async (locs: string) => {
  const editor = await sdk.contact.editTags();
  editor.set('cfa_locations', locs.replace(/,/g, ', '));
  await editor.apply();
};

//adds sub list ID from the sub list for both the web and app to keep them synchronized
export const updateSubscriptionListSubscribe = async (subList: string) => {
  const editor = await sdk.contact.subscriptions.edit();
  editor.subscribe(subList, 'web');
  editor.subscribe(subList, 'app');
  await editor.apply();
};

//removes sub list ID from the sub list for both the web and app to keep them synchronized
export const updateSubscriptionListUnsubscribe = async (subList: string) => {
  const editor = await sdk.contact.subscriptions.edit();
  editor.unsubscribe(subList, 'web');
  editor.unsubscribe(subList, 'app');
  await editor.apply();
};

//resets named user (removes) for non-prod to release more Named User channels to avoid the limit
export const resetNamedUser = () => {
  import.meta.env.VITE_APP_ENVIRONMENT !== 'prod' && sdk.contact.reset();
};

//checks if sub list ID is in the sub list for change notification preference on the profile page
export const fetchNotificationPrefFlag = async (subList: string) => {
  let fetchResult = false;
  await sdk.contact.subscriptions
    .list()
    .then((result: Record<string, unknown>) => {
      fetchResult = !!Object.entries(result).find(x => x[0] === subList);
    });
  return fetchResult;
};
