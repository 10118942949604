// 86acbd31cd7c09cf30acb66d2fbedc91daa48b86:1720639400.6470501
!(function (n, r, e, t, c) {
  var i,
    o = 'Promise' in n,
    u = {
      then: function () {
        return u;
      },
      catch: function (n) {
        return n(new Error('Airship SDK Error: Unsupported browser')), u;
      },
    },
    s = o
      ? new Promise(function (n, r) {
          i = function (e, t) {
            e ? r(e) : n(t);
          };
        })
      : u;
  (s._async_setup = function (n) {
    if (o)
      try {
        i(null, n(c));
      } catch (n) {
        i(n);
      }
  }),
    (n[t] = s);
  var a = r.createElement('script');
  (a.src = e),
    (a.async = !0),
    (a.id = '_uasdk'),
    (a.rel = t),
    r.head.appendChild(a);
})(window, document, 'https://aswpsdkus.com/notify/v2/ua-sdk.min.js', 'UA', {
  vapidPublicKey: import.meta.env.VITE_AIRSHIP_PUBLIC_KEY,
  appKey: import.meta.env.VITE_AIRSHIP_APP_KEY,
  token: import.meta.env.VITE_AIRSHIP_TOKEN,
});
