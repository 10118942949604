import { FC, useState, useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import {
  MINUTE,
  TWO_MINUTES,
  HOUR,
  ONE_HOUR_CUTOFF,
  DAY,
  DAY_CUTOFF,
  TWO_DAYS,
  WEEK,
  WEEK_CUTOFF,
} from '../../utils/date-time/constants';
interface Props {
  date: string;
  constrainSize?: 'small' | 'medium';
  lowerCase?: boolean;
}

export const formatter = (
  timeNow: DateTime,
  date: string,
  constrainSize: 'small' | 'medium' | undefined,
  lowerCase?: boolean
) => {
  const passedDate = DateTime.fromISO(date);
  const elapsed = Interval.fromDateTimes(passedDate, timeNow).length('seconds');

  if (constrainSize === 'small') {
    const [value, unit] =
      elapsed < MINUTE
        ? ['now', '']
        : elapsed < TWO_MINUTES
        ? [1, ' m']
        : elapsed < HOUR
        ? [Math.floor(elapsed / MINUTE), ' m']
        : elapsed < ONE_HOUR_CUTOFF
        ? [1, ' h']
        : elapsed < DAY
        ? [Math.floor(elapsed / HOUR), ' h']
        : elapsed < DAY_CUTOFF
        ? [1, ' d']
        : [null];
    return value
      ? `${value}${unit}`
      : passedDate.toLocaleString({ month: 'short', day: 'numeric' });
  }

  if (constrainSize === 'medium') {
    const [value, unit] =
      elapsed < MINUTE
        ? ['now', '']
        : elapsed < TWO_MINUTES
        ? [1, ' min ago']
        : elapsed < HOUR
        ? [Math.floor(elapsed / MINUTE), ' min ago']
        : elapsed < ONE_HOUR_CUTOFF
        ? [1, ' hr ago']
        : elapsed < DAY
        ? [Math.floor(elapsed / HOUR), ' hr ago']
        : elapsed < DAY_CUTOFF
        ? [1, ' day ago']
        : [null];
    return value
      ? `${value}${unit}`
      : passedDate.toLocaleString({ month: 'short', day: 'numeric' });
  }

  const [value, unit] =
    elapsed < MINUTE
      ? [`${lowerCase ? 'j' : 'J'}ust now`, '']
      : elapsed < TWO_MINUTES
      ? [1, ' minute ago']
      : elapsed < HOUR
      ? [Math.floor(elapsed / MINUTE), ' minutes ago']
      : elapsed < ONE_HOUR_CUTOFF
      ? [1, ' hour ago']
      : elapsed < DAY
      ? [Math.floor(elapsed / HOUR), ' hours ago']
      : elapsed < DAY_CUTOFF
      ? [`${lowerCase ? 'y' : 'Y'}esterday`, '']
      : elapsed < TWO_DAYS
      ? [
          `${lowerCase ? 'y' : 'Y'}esterday, ${passedDate.toLocaleString(
            DateTime.TIME_SIMPLE
          )}`,
          '',
        ]
      : elapsed < WEEK
      ? [Math.round(elapsed / DAY), ' days ago']
      : elapsed < WEEK_CUTOFF
      ? ['1 week ago', '']
      : [null];
  return value
    ? `${value}${unit}`
    : passedDate.toLocaleString(DateTime.DATE_MED);
};

const RelativeTime: FC<Props> = ({ date, constrainSize, lowerCase }) => {
  const now = () => DateTime.now();
  const [timeNow, setTimeNow] = useState(now());
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeNow(now());
    }, 60000);
    return () => clearTimeout(timer);
  }, [date, timeNow]);

  return <>{formatter(timeNow, date, constrainSize, lowerCase)}</>;
};

export default RelativeTime;
