import { FC } from 'react';
import { ModalHeader } from '@cfa/react-components';
import { themeColors, themeSizes } from '../../../../styles/globalStyleVars';
import styled from 'styled-components';
import { IconConfetti } from '@tabler/icons-react';

const FeedbackModalHeader = styled(ModalHeader)<{
  $isExtraSmallScreen: boolean;
}>`
  ${props => !props.$isExtraSmallScreen && `padding-bottom: ${themeSizes.xl}`};
  padding-top: 56px;
  font-size: ${props => (!props.$isExtraSmallScreen ? themeSizes.lg : '20px')};
  white-space: pre-wrap;
  color: ${themeColors.colorGray_6};
`;

interface Props {
  feedbackSuccess: boolean;
  isExtraSmallScreen: boolean;
  title: string;
}

const WebsiteFeedbackModalHeader: FC<Props> = ({
  feedbackSuccess,
  isExtraSmallScreen,
  title,
}) => {
  return !feedbackSuccess ? (
    <FeedbackModalHeader variant='h3' $isExtraSmallScreen={isExtraSmallScreen}>
      {title}
    </FeedbackModalHeader>
  ) : (
    <ModalHeader variant='h3'>
      <IconConfetti
        size={30}
        color={themeColors.primaryRed}
        style={{ marginBottom: themeSizes.sm }}
      />
      <div style={{ paddingBottom: '12px', color: themeColors.colorGray_6 }}>
        Thank you for your help
      </div>
    </ModalHeader>
  );
};

export default WebsiteFeedbackModalHeader;
