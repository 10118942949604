import { useEffect, useState } from 'react';

export interface OrientationState {
  angle: number;
  type: string;
  height: number;
}

const defaultOrientation: OrientationState = {
  angle: 0,
  type: 'landscape-primary',
  height: 768,
};

const useOrientation = (
  initialState: OrientationState = defaultOrientation
) => {
  const [orientation, setOrientation] = useState(initialState);

  useEffect(() => {
    const screen = window.screen;
    let mounted = true;

    const onChange = () => {
      const afterOrientationChange = () => {
        if (mounted) {
          const { orientation } = screen as Screen;
          const height = window.innerHeight;

          // Deprecated so checking for undefined - used for Safari
          if (window.orientation !== undefined) {
            return setOrientation({
              angle:
                typeof window.orientation === 'number' ? window.orientation : 0,
              type: '',
              height,
            });
          }

          if (orientation) {
            const { angle, type } = orientation;
            setOrientation({ angle, type, height });
          } else {
            setOrientation(initialState);
          }
        }
        window.removeEventListener('resize', afterOrientationChange);
      };
      window.addEventListener('resize', afterOrientationChange);
      afterOrientationChange();
    };

    window.addEventListener('orientationchange', onChange);
    onChange();

    return () => {
      mounted = false;
      window.removeEventListener('orientationchange', onChange);
    };
  }, []);

  return orientation;
};

export default useOrientation;
