import { FC } from 'react';
import { Typography } from '@cfa/react-components';
import styled from 'styled-components';
import useOrientation from '../../hooks/useOrientation';
import { IconDeviceMobile } from '@tabler/icons-react';
import IconShadow from '../../assets/celebration-shadow.svg';
import { themeColors, themeSizes } from '../../styles/globalStyleVars';
import { callEvent } from '../../utils/Amplitude/AmplitudeUtilities';

const IconShadowImage = styled.img`
  margin-top: ${themeSizes.xs};
  margin-bottom: ${themeSizes.md};
  width: 62px;
  height: auto;
  padding: 0;
`;

const PromptWrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  z-index: 99999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const OrientationPrompt: FC = () => {
  const { angle, type, height } = useOrientation();
  const isMobileLandscape =
    height < 501 &&
    [90, -90, 270].includes(angle) &&
    ['', 'landscape-primary', 'landscape-secondary'].includes(type);

  if (isMobileLandscape) {
    callEvent('rotate device prompt', {});

    return (
      <PromptWrapper>
        <IconDeviceMobile size={32} color={themeColors.secondaryBlue} />
        <IconShadowImage src={IconShadow} alt='Mobile Device Icon Shadow' />
        <Typography variant='h3' style={{ marginBottom: themeSizes.lg }}>
          Please rotate your device
        </Typography>
        <Typography>
          We don’t support landscape mode. <br />
          Please go back to portrait mode for the best experience.
        </Typography>
      </PromptWrapper>
    );
  }
  return <></>;
};
