import {
  useState,
  useContext,
  FC,
  Dispatch,
  SetStateAction,
  ReactNode,
  lazy,
  useEffect,
} from 'react';
import { useOktaAuth } from '@okta/okta-react/';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Drawer,
  IconButton,
  NavLink,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
  TopNavigation,
  TopNavigationContent,
  useBreakpoints,
  useMediaQuery,
  Typography,
} from '@cfa/react-components';
import {
  Link,
  NavLink as ReactRouterNavLink,
  useHistory,
} from 'react-router-dom';
import styled from 'styled-components';
import SWLogo from '../../assets/sw-logo-top-nav.svg';
import { UserContext } from '../../user/UserContext';
import { UserContextType } from '../../@types/user';
import { useLocationStore } from '../../stores/locationStore';
import {
  mediaQueries,
  themeColors,
  themeSizes,
} from '../../styles/globalStyleVars';
import { callEvent } from '../../utils/Amplitude/AmplitudeUtilities';
import PreventNavigationModal from '../Global/NavigationPrompt/PreventNavigationModal';
import CalendarLogo from '../../assets/calendar.svg';
import IntroductionLogo from '../../assets/introduction.svg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useGetUserOnboarding } from '../../utils/api/requests/useQueries';
import {
  IconAlertTriangleFilled,
  IconLogout,
  IconMenu2,
  IconSearch,
  IconSettings,
  IconX,
} from '@tabler/icons-react';
import { useSignout } from '../../user/useSignout';

const IntroductionModal = lazy(
  () => import('../Global/Modals/IntroductionModal/IntroductionModal')
);
const WebsiteFeedbackModal = lazy(
  () => import('../Global/Modals/WebsiteFeedbackModal/WebsiteFeedbackModal')
);
const LogoutModal = lazy(
  () => import('../Global/Modals/LogoutModal/LogoutModal')
);

const NavLogoLink = styled(Link)`
  line-height: 1;
  margin-right: ${themeSizes.sm};
  img {
    width: ${themeSizes.xxl};
    height auto;
  }

`;

const NavDrawer = styled(Drawer)`
  > .cfa-surface {
    overflow-y: hidden;
    z-index: 3000;
  }
`;

const NavDrawerCloseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 74px;
  padding: 4px 0px;

  button {
    width: auto;
    align-self: flex-end;
  }

  svg {
    height: 18px;
    width: 18px;
  }
`;

const NavDrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
`;

const NavDrawerContentSection = styled.div`
  display: flex;
  flex-direction: column;

  &:last-child {
    padding: ${themeSizes.md} 12px ${themeSizes.xl};
  }
`;

const NameText = styled.span`
  font-weight: 700;
  color: ${themeColors.colorGray_6};
  &.mobileUsername {
    position: relative;
    top: -10px;
  }
`;

const DrawerButton = styled(Button)`
  display: inline-flex;
  justify-content: flex-start;
  padding: 12px ${themeSizes.xs};
  letter-spacing: 0;
  font-weight: 500;
`;

const AlertDrawerNavButton = styled(IconButton)<{ $apiError: boolean }>`
  color: ${themeColors.secondaryBlue} !important;
  margin-top: 6px;
  margin-right: 12px;

  ${mediaQueries.sm_up} {
    margin-right: ${themeSizes.lg};
  }

  > svg {
    ${props =>
      props.$apiError && `color: ${themeColors.errorColor} !important`};
  }
`;

const IntroductionButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  border: 1px solid ${themeColors.colorGray_1};
  border-radius: ${themeSizes.sm};
  padding: 10px;
`;

interface Props {
  alertsDrawerOpen: boolean;
  setAlertsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  alertsTotal: number;
  alertsError: boolean;
}

interface AlertButtonProps {
  children: ReactNode;
}

const Navigation: FC<Props> = ({
  alertsDrawerOpen,
  setAlertsDrawerOpen,
  alertsTotal,
  alertsError,
}) => {
  const {
    userState,
    isFormDirty,
    setLogoutClicked,
    logoutClicked,
    setIsOnboarded,
    isOnboarded,
    userAvatarPath,
    isSessionTimeout,
  } = useContext(UserContext) as UserContextType;
  const { activeLocation } = useLocationStore();
  const { handleLogout } = useSignout();
  const { oktaAuth } = useOktaAuth();
  const [open, setOpen] = useState<boolean>(false);
  const breakpoints = useBreakpoints();
  const isBelowLg = useMediaQuery(breakpoints.down('lg'));
  const isBelowMd = useMediaQuery(breakpoints.down('md'));
  const isBelowSm = useMediaQuery(breakpoints.down('sm'));
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logUserOut, setLogUserOut] = useState(false);
  const [showIntroductionModal, setShowIntroductionModal] =
    useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const { ['equipment-tab']: equipmentTab } = useFlags();
  const { ['whats-new']: whatsNew } = useFlags();
  const { ['onboarding-flow']: onboardingFlow } = useFlags();
  const history = useHistory();
  const locationPath = activeLocation ? `/${activeLocation}` : '';
  const { data: userOnboarding, isSuccess: userOnboardingSuccess } =
    useGetUserOnboarding();

  useEffect(() => {
    if (!userOnboardingSuccess) return;
    setShowIntroductionModal(!userOnboarding);
    setIsOnboarded(!!userOnboarding);
  }, [userOnboardingSuccess]);

  useEffect(() => {
    if (!logUserOut && !isSessionTimeout) return;
    if (isSessionTimeout) {
      handleLogout();
      return;
    }
    handleLogout(1500);
  }, [logUserOut, isSessionTimeout]);

  const closeDrawer = () => {
    setOpen(false);
  };

  const login = async () => {
    callEvent('clicked navlink', {
      locationNum: activeLocation,
      linkName: 'Login',
    });

    oktaAuth.signInWithRedirect({ originalUri: '/' });
  };

  //logs mobile events
  const handleMobileNavClick = async (clickedLink: string) => {
    callEvent('clicked mobile navlink', {
      locationNum: activeLocation,
      linkName: clickedLink,
    });

    closeDrawer();
  };

  const handleAlertsIconClick = () => {
    setAlertsDrawerOpen(!alertsDrawerOpen);
    callEvent('view alerts', {});
  };

  //when logout is clicked it will launch logout modal which will display for 1.5 seconds and then log user out
  const logout = async () => {
    isFormDirty ? setShowLogoutModal(false) : setShowLogoutModal(true);
    localStorage.removeItem('location-storage');

    callEvent('clicked navlink', {
      locationNum: activeLocation,
      linkName: 'Logout',
    });

    isFormDirty && setLogoutClicked(true);
  };

  // Add navigation links here
  const navLinks = [
    { route: '/', linkText: 'Home' },
    { route: `/tickets${locationPath}`, linkText: 'Tickets' },
    { route: `/forms`, linkText: 'Open a Ticket' },
    // Feature Flag - equipmentTab
    ...(equipmentTab
      ? [
          {
            route: `/equipment${locationPath}`,
            linkText: 'Equipment',
          },
        ]
      : []),
  ];

  const AlertsIconButton: FC<AlertButtonProps> = ({ children }) => {
    return (
      <AlertDrawerNavButton
        onClick={() => handleAlertsIconClick()}
        $apiError={alertsError}
        data-testid='alerts-outages-btn'
      >
        {alertsTotal > 0 ? (
          <Badge content={alertsTotal}>{children}</Badge>
        ) : (
          <>{children}</>
        )}
      </AlertDrawerNavButton>
    );
  };

  return (
    <>
      <TopNavigation>
        <TopNavigationContent section='start'>
          <NavLogoLink
            to='/'
            onClick={() =>
              callEvent('clicked navlink', {
                locationNum: activeLocation,
                linkName: 'Nav Logo',
              })
            }
          >
            <img src={SWLogo} alt='Chick-fil-A Support Logo' />
          </NavLogoLink>
        </TopNavigationContent>
        <TopNavigationContent section='middle' hideContent={isBelowMd}>
          {navLinks.map(({ route, linkText }) => (
            <NavLink
              key={linkText}
              as={ReactRouterNavLink}
              to={route}
              isActive={
                route === '/' &&
                ['/', '/home'].includes(window.location.pathname)
                  ? true
                  : undefined
              }
              exact={route === '/'}
              data-testid={`nav_${linkText.replace(/\s/g, '-').toLowerCase()}`}
              onClick={() =>
                callEvent('clicked navlink', {
                  locationNum: activeLocation,
                  linkName: linkText,
                })
              }
              style={
                isBelowLg
                  ? { margin: '0 10px' }
                  : { margin: `0 ${themeSizes.md}` }
              }
            >
              {linkText}
            </NavLink>
          ))}
        </TopNavigationContent>
        <TopNavigationContent section='end'>
          {(whatsNew || onboardingFlow) && (
            <NavLink
              onClick={() => {
                setShowIntroductionModal(true);
                callEvent(
                  whatsNew ? 'view whats new modal' : 'view onboarding modal',
                  {
                    locationNum: activeLocation,
                    path: window.location.href,
                  }
                );
              }}
              data-testid='nav_whats-new'
              style={{ marginTop: '2px', marginRight: themeSizes.sm }}
            >
              <IntroductionButtonWrapper>
                {!isBelowLg && (
                  <Typography
                    color='secondary'
                    fontWeight='medium'
                    style={{ marginRight: '10px' }}
                  >
                    See What&apos;s New
                  </Typography>
                )}
                <img src={IntroductionLogo} alt='Introduction Logo' />
              </IntroductionButtonWrapper>
            </NavLink>
          )}
          <NavLink
            as={ReactRouterNavLink}
            to={`/search${window.location.search}`}
            activeClassName='selected'
            onClick={() =>
              callEvent('clicked search icon', {
                locationNum: activeLocation,
              })
            }
            data-testid='nav_search'
            style={{ marginTop: '6px', marginRight: '12px' }}
          >
            <IconSearch size={28} color={themeColors.secondaryBlue} />
          </NavLink>
          <NavLink
            as={ReactRouterNavLink}
            to={`/appointments`}
            activeClassName='selected'
            onClick={() =>
              callEvent('view all appointments', {
                locationNum: activeLocation,
              })
            }
            data-testid='nav_appointments'
            style={{ marginTop: '5px', marginRight: '20px' }}
          >
            <img src={CalendarLogo} alt='Calendar Logo' />
          </NavLink>
          <AlertsIconButton>
            <IconAlertTriangleFilled size={27} />
          </AlertsIconButton>
          {!isBelowSm && (
            <PopoverMenu placement='bottom-end'>
              <PopoverMenuToggleButton
                as={Avatar}
                name={`${userState.userDetails?.display_name} ${userState.userDetails?.display_last_name}`}
                imageSrc={userAvatarPath}
                alt='Profile Avatar'
                onClick={() =>
                  callEvent('clicked navlink', {
                    locationNum: activeLocation,
                    linkName: 'Profile Avatar',
                  })
                }
                data-testid='nav-avatar-btn'
                style={{
                  cursor: 'pointer',
                  marginBottom: themeSizes.xs,
                  marginRight: isBelowMd ? themeSizes.md : '0px',
                }}
              />
              <PopoverMenuContent
                style={{ zIndex: 9999 }}
                className='popper-menu'
                data-testid='nav-user-menu'
              >
                {userState.isLoggedIn ? (
                  <>
                    <div
                      style={{
                        padding: '12px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Avatar
                        color='primary'
                        href=''
                        name={`${userState.userDetails?.display_name} ${userState.userDetails?.display_last_name}`}
                        imageSrc={userAvatarPath}
                        alt='Profile Avatar'
                        style={{ marginRight: '12px', display: 'inline-flex' }}
                      />
                      <NameText data-testid='nav-user-name'>{`${userState.userDetails?.display_name} ${userState.userDetails?.display_last_name}`}</NameText>
                    </div>
                    <Divider />
                    <PopoverMenuItem
                      id='settings'
                      style={{ paddingBottom: '6px' }}
                      onClick={() => {
                        history.push('/settings');
                        callEvent('clicked navlink', {
                          locationNum: activeLocation,
                          linkName: 'Settings',
                        });
                      }}
                    >
                      <IconSettings style={{ marginRight: '12px' }} />
                      Settings
                    </PopoverMenuItem>
                    <PopoverMenuItem
                      id='signout'
                      style={{ paddingTop: '6px' }}
                      onClick={logout}
                    >
                      <IconLogout style={{ marginRight: '12px' }} />
                      Log Out
                    </PopoverMenuItem>
                  </>
                ) : (
                  <PopoverMenuItem id='signin' onClick={login}>
                    Log In
                  </PopoverMenuItem>
                )}
              </PopoverMenuContent>
            </PopoverMenu>
          )}
          {isBelowMd && (
            <IconButton
              aria-label={`${open ? 'close' : 'open'} menu`}
              className='collapse-menu'
              size='sm'
              onClick={() => setOpen(prevState => !prevState)}
            >
              {open ? (
                <IconX color={themeColors.colorGray_6} />
              ) : (
                <IconMenu2 color={themeColors.colorGray_6} />
              )}
            </IconButton>
          )}
        </TopNavigationContent>
      </TopNavigation>
      <NavDrawer
        open={isBelowMd && open}
        onClose={closeDrawer}
        content={
          <>
            <NavDrawerCloseWrapper>
              <IconButton color='secondary' onClick={() => closeDrawer()}>
                <IconX
                  color={themeColors.colorGray_6}
                  style={{ height: '26px', width: '26px' }}
                />
              </IconButton>
            </NavDrawerCloseWrapper>
            <NavDrawerContentWrapper>
              <NavDrawerContentSection>
                {navLinks.map(({ route, linkText }) => (
                  <NavLink
                    key={linkText}
                    as={ReactRouterNavLink}
                    to={route}
                    isActive={
                      route === '/' &&
                      ['/', '/home'].includes(window.location.pathname)
                        ? true
                        : undefined
                    }
                    exact={route === '/'}
                    onClick={() => handleMobileNavClick(linkText)}
                  >
                    {linkText}
                  </NavLink>
                ))}
              </NavDrawerContentSection>
              <NavDrawerContentSection>
                {isBelowSm && (
                  <div>
                    <Avatar
                      color='primary'
                      href=''
                      name={`${userState.userDetails?.display_name} ${userState.userDetails?.display_last_name}`}
                      imageSrc={userAvatarPath}
                      alt='Profile Avatar'
                      style={{ marginRight: '12px', display: 'inline-flex' }}
                    />
                    {userState.isLoggedIn ? (
                      <>
                        <NameText className='mobileUsername'>
                          {`${userState.userDetails?.display_name} ${userState.userDetails?.display_last_name}`}
                        </NameText>
                        <Divider
                          style={{ margin: `10px 0 ${themeSizes.xs}` }}
                        />
                        <DrawerButton
                          color='secondary'
                          onClick={() => {
                            history.push('/settings');
                            callEvent('clicked navlink', {
                              locationNum: activeLocation,
                              linkName: 'Settings (mobile)',
                            });
                            closeDrawer();
                          }}
                          variant='text'
                        >
                          <IconSettings style={{ marginRight: '12px' }} />
                          Settings
                        </DrawerButton>
                        <DrawerButton
                          color='secondary'
                          onClick={logout}
                          variant='text'
                        >
                          <IconLogout style={{ marginRight: '12px' }} />
                          Log Out
                        </DrawerButton>
                      </>
                    ) : (
                      <DrawerButton
                        color='secondary'
                        onClick={login}
                        variant='text'
                      >
                        Log In
                      </DrawerButton>
                    )}
                  </div>
                )}
              </NavDrawerContentSection>
            </NavDrawerContentWrapper>
          </>
        }
      />

      <IntroductionModal
        show={showIntroductionModal}
        onClose={() => setShowIntroductionModal(false)}
        setShowFeedbackModal={setShowFeedbackModal}
        setIsOnboarded={setIsOnboarded}
        isOnboarded={isOnboarded}
        showWhatsNewFeatureFlag={whatsNew}
      />

      <WebsiteFeedbackModal
        show={showFeedbackModal}
        onClose={() => {
          setShowFeedbackModal(false);
          whatsNew && setShowIntroductionModal(true);
        }}
        onCancel={() => setShowFeedbackModal(false)}
        title={`Tell us your thoughts about the\nnew website.`}
        subTitle={
          <>
            Please tell us what&apos;s on your mind. The more detail you share
            the better we can serve you.
          </>
        }
        hideTicketButton
        screenName='Introduction'
        closeButtonText={whatsNew ? "Back to What's New" : 'Close'}
        whatsNewFeedback={whatsNew}
      />

      <PreventNavigationModal
        onClose={() => setLogoutClicked(false)}
        onCancel={() => setLogoutClicked(false)}
        onConfirm={() => setShowLogoutModal(true)}
        show={logoutClicked && !showLogoutModal}
        title='Discard Ticket?'
        subTitle='Leaving will discard all progress you’ve made toward opening your
        ticket. Are you sure you want to continue?'
        confirmButtonText='Discard Ticket'
      />

      <LogoutModal
        showLogoutModal={showLogoutModal}
        setShowLogoutModal={setShowLogoutModal}
        logUserOut={logUserOut}
        setLogUserOut={setLogUserOut}
        setLogoutClicked={setLogoutClicked}
        userName={userState?.userDetails?.['cfa-login']}
      />
    </>
  );
};

export default Navigation;
