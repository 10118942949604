import { fetchAttachment } from '../utils/api/requests/requests';

async function fetchTeamAvatar(guid: string) {
  try {
    const URL = await fetchAttachment(
      `${
        import.meta.env.VITE_SECURED_ASSETS_URL
      }/team-member-pictures/${guid}.jpg`
    );
    return URL;
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export async function setUserAvatar(
  audience: string,
  guid: string,
  employeeNumber?: string
) {
  if (audience === 'OPERATOR_AUDIENCE') {
    return Promise.resolve(
      `${import.meta.env.VITE_CDN_URL}/photos/people/${employeeNumber}.jpg`
    );
  }
  if (audience === 'TEAM_MBR_AUDIENCE') {
    return fetchTeamAvatar(guid).then(res => res);
  }
  return Promise.resolve(
    employeeNumber
      ? `https://prod-cfa-staff-api-employee-images.s3.amazonaws.com/${employeeNumber}.jpg`
      : undefined
  );
}
