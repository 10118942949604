export const generateErrorOptions = (
  page: string,
  type: string,
  isConfidential: boolean | undefined
) => {
  const sharedErrorOptions = {
    showButton: true,
    buttonText: 'Try Again',
    errorTitle: 'Loading Error',
  };
  const errorOptions = [
    {
      errorType: 'fetch',
      ...sharedErrorOptions,
      errorMessage:
        'There was an error while loading the content for this page. Please try again.',
    },
    {
      errorType: 'ticketNotFound',
      showButton: true,
      errorTitle: 'Ticket Not Found',
      buttonText: 'Search Page',
      errorMessage: `We did not find that ticket, or the URL is incorrect.\n Try searching for your ticket on the search page.`,
    },
    {
      errorType: 'appointmentNotFound',
      showButton: true,
      errorTitle: 'Appointment Not Found',
      buttonText: 'Back to My Appointments',
      errorMessage:
        'We did not find that appointment, or the URL is incorrect.',
    },
    {
      errorType: ['content-fetch', 'modal-fetch'],
      ...sharedErrorOptions,
      errorMessage:
        'There was an error while loading this content. Please try again.',
    },
    {
      errorType: 'insufficient',
      showButton: isConfidential ? false : true,
      buttonText:
        page === 'knowledgeArticle'
          ? 'Search Chick-fil-A Support'
          : page === 'appointmentDetails'
          ? 'Back to My Appointments'
          : 'Request Access',
      errorTitle: 'Insufficient Access',
      errorMessage: `You don't have access to view ${
        page === 'knowledgeArticle'
          ? 'this knowledge article.'
          : page === 'ticketDetails'
          ? 'this ticket'
          : page === 'appointmentDetails'
          ? 'this appointment'
          : 'these tickets'
      }`,
      errorSecondaryMessage:
        page === 'knowledgeArticle' && 'You can search for more help below.',
    },
  ];

  return errorOptions.find(option => option.errorType.includes(type));
};
