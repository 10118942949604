import {
  FC,
  useState,
  useEffect,
  useLayoutEffect,
  ReactNode,
  Suspense,
  useContext,
} from 'react';
import { useOktaAuth } from '@okta/okta-react/';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../user/UserContext';
import { UserContextType } from '../@types/user';
import Navigation from '../components/Navigation/Navigation';
import { LoadingIndicator } from '@cfa/react-components';
import { Footer } from '../components/Footer/Footer';
import styled from 'styled-components';
import { AlertsDrawer } from '../components/Global/Alerts/AlertsDrawer';
import { useAlertsStore } from '../stores/alertsStore';
import { AlertsBanner } from '../components/Global/Alerts/AlertsBanner';
import { useAlertsOutagesSubscription } from '../utils/api/requests/useSubscriptions';
import { initializeBugsnag } from '../utils/BugSnag/BugSnagUtilities';
import { AlertInterface } from '../interfaces/AlertInterface';
import {
  useGetAirshipUserProperties,
  useGetAnnouncements,
} from '../utils/api/requests/useQueries';
import { useLocationStore } from '../stores/locationStore';
import {
  registerUser,
  updateAudienceAttribute,
  updateLocationTag,
} from '../utils/Airship/AirshipUtilities';

interface Props {
  children: ReactNode;
}

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: auto;
`;

export const AppAuthWrapper: FC<Props> = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const pathname = useLocation().pathname;
  const { setSubscribedLocations } = useLocationStore();
  const { userState } = useContext(UserContext) as UserContextType;

  const [alertsDrawerOpen, setAlertsDrawerOpen] = useState<boolean>(false);
  const [alertChildCaseOpen, setAlertChildCaseOpen] = useState<
    AlertInterface | undefined
  >(undefined);

  useEffect(() => {
    oktaAuth.tokenManager.on('expired', async function () {
      const renewToken = await oktaAuth.token.renewTokens();
      await oktaAuth.tokenManager.setTokens(renewToken);
    });
    return () => oktaAuth.tokenManager.off('expired');
  }, []);

  const {
    data: alertsAndOutages,
    error: alertsError,
    isLoading: alertsLoading,
    refetch: refetchAlerts,
  } = useGetAnnouncements(authState?.isAuthenticated);
  const { setAlertsOutages, alertsOutages } = useAlertsStore();

  const { data: airshipUserProperties } = useGetAirshipUserProperties(
    userState?.userDetails?.['cfa-guid'],
    !!userState?.userDetails?.['cfa-guid']
  );

  useLayoutEffect(() => {
    setSubscribedLocations(
      airshipUserProperties
        ?.find(x => x.name === 'opt_in_alerts')
        ?.tags?.filter(tag => !tag.startsWith('CS'))
    );
  }, [airshipUserProperties]);

  const showAlertBanner = ['/', '/forms'].includes(pathname);

  const bannerDisplayAlerts = alertsOutages
    .filter(alert => !alert.markDismissed)
    .slice(0, 2);

  useEffect(() => {
    if (!authState?.idToken) return;

    initializeBugsnag(authState.idToken.claims);

    //Airship
    registerUser(authState?.idToken?.claims['cfa-guid'].toString());
    updateAudienceAttribute(authState?.idToken?.claims?.cfa_aud.toString());
    authState.idToken.claims.userType !== 'Staff' &&
      authState.idToken.claims.userType !== 'Contractor' &&
      updateLocationTag(authState?.idToken?.claims['cfa-locations'].toString());
  }, [authState?.isAuthenticated]);

  useLayoutEffect(() => {
    if (alertsAndOutages) {
      setAlertsOutages(alertsAndOutages);
    }
  }, [alertsAndOutages]);

  useAlertsOutagesSubscription();

  return (
    <>
      {authState?.isAuthenticated ? (
        <PageWrapper>
          <AlertsDrawer
            alertsDrawerOpen={alertsDrawerOpen}
            setAlertsDrawerOpen={setAlertsDrawerOpen}
            setAlertChildCaseOpen={setAlertChildCaseOpen}
            alertChildCaseOpen={alertChildCaseOpen}
            alertsError={!!alertsError}
            alertsLoading={alertsLoading}
            refetchAlerts={refetchAlerts}
          />
          <Navigation
            alertsDrawerOpen={alertsDrawerOpen}
            setAlertsDrawerOpen={setAlertsDrawerOpen}
            alertsTotal={alertsAndOutages ? alertsAndOutages.length : 0}
            alertsError={alertsError ? true : false}
          />
          {!alertsLoading &&
            !alertsError &&
            showAlertBanner &&
            bannerDisplayAlerts.length > 0 && (
              <AlertsBanner
                alertsAndOutages={bannerDisplayAlerts}
                setAlertsDrawerOpen={() => setAlertsDrawerOpen(true)}
                setAlertChildCaseOpen={setAlertChildCaseOpen}
              />
            )}
          <Suspense
            fallback={
              <LoadingIndicator
                size='md'
                variant='page'
                style={{ margin: '3rem auto' }}
              />
            }
          >
            {children}
          </Suspense>
          <Footer />
        </PageWrapper>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
