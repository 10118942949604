import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { AlertInterface } from '../interfaces/AlertInterface';
export interface AlertsState {
  alertsOutages: AlertInterface[];
  setAlertsOutages: (alerts: AlertInterface[]) => void;
  setAllAlertsDismissed: () => void;
  setAlertDismissed: (alert: AlertInterface) => void;
}

export const useAlertsStore = create<AlertsState>()(
  persist(
    (set, get) => ({
      alertsOutages: [],
      setAllAlertsDismissed: async () => {
        const markAllAlertsDismissed = async () => {
          const alerts = get().alertsOutages;
          alerts.map((alert: AlertInterface) => {
            alert.markDismissed = true;
          });
          return alerts;
        };
        set({ alertsOutages: await markAllAlertsDismissed() });
      },
      setAlertDismissed: async (alert: AlertInterface) => {
        const markAlertDismissed = async () => {
          const alerts = get().alertsOutages;
          const foundIndex = alerts.findIndex(x => x.id == alert.id);
          alerts[foundIndex].markDismissed = true;
          return alerts;
        };
        set({ alertsOutages: await markAlertDismissed() });
      },
      setAlertsOutages: async alerts => {
        const getBannerAlerts = async (alerts: AlertInterface[]) => {
          const newAlerts: AlertInterface[] = [];
          const storedAlerts = get().alertsOutages;
          alerts.map(alert => {
            const alertIndex = storedAlerts.findIndex(
              (parsedAlert: AlertInterface) =>
                parsedAlert.id === alert.id &&
                parsedAlert.updatedOn === alert.updatedOn
            );
            if (alertIndex === -1) {
              newAlerts.push(Object.assign(alert, { markDismissed: false }));
            } else {
              newAlerts.push(
                Object.assign(alert, {
                  markDismissed: storedAlerts[alertIndex].markDismissed,
                })
              );
            }
          });
          return newAlerts;
        };
        set({ alertsOutages: await getBannerAlerts(alerts) });
      },
    }),
    {
      partialize: state => ({
        alertsOutages: state.alertsOutages,
      }),
      name: 'alerts-outages-storage',
    }
  )
);

export {};
