import { FC, useState, useEffect } from 'react';
import {
  Modal,
  MessageBlock,
  Row,
  useBreakpoints,
  useMediaQuery,
} from '@cfa/react-components';
import styled from 'styled-components';
import WebsiteFeedbackModalHeader from './WebsiteFeedbackModalHeader';
import WebsiteFeedbackModalBody from './WebsiteFeedbackModalBody';
import { useSubmitFormToCreateCase } from '../../../../utils/api/requests/useMutations';
import { useLocationStore } from '../../../../stores/locationStore';
import { callEvent } from '../../../../utils/Amplitude/AmplitudeUtilities';

const MessageBlockWrapper = styled(Row)`
  justify-content: center;
  padding-bottom: 12px;
  position: absolute;
  left: 0;
  right: 0;
`;

const ErrorMessageBlock = styled(MessageBlock)`
  min-width: 0px;
  width: fit-content;
`;

interface Props {
  onClose: () => void;
  onCancel?: () => void;
  show: boolean;
  title: string;
  subTitle: JSX.Element;
  screenName?: string;
  inputPlaceholder?: string;
  redirectToHelpOnline?: () => void;
  userGUID?: string;
  hideTicketButton?: boolean;
  closeButtonText?: string;
  whatsNewFeedback?: boolean;
  featureFlowFeedback?: boolean;
}

const WebsiteFeedbackModal: FC<Props> = ({
  onClose,
  onCancel,
  show,
  title,
  subTitle,
  screenName = window.location.href,
  inputPlaceholder = 'Please describe how we can improve...',
  redirectToHelpOnline,
  userGUID,
  hideTicketButton,
  closeButtonText = 'Close',
  whatsNewFeedback = false,
  featureFlowFeedback = false,
}) => {
  const [feedbackSuccess, setFeedbackSuccess] = useState<boolean>(false);
  const [feedbackError, setFeedbackError] = useState<boolean>(false);
  const [feedbackComment, setFeedbackComment] = useState<string>('');
  const breakpoints = useBreakpoints();
  const isExtraSmallScreen = useMediaQuery(breakpoints.down('sm'));
  const { activeLocation } = useLocationStore();
  const {
    mutate: submitFeedback,
    isLoading: submitFeedbackLoading,
    error: submitFeedbackError,
    isSuccess: submitFeedbackSuccess,
    data: submitFeedbackResponseData,
  } = useSubmitFormToCreateCase('support_products_feedback', false);

  const submitFeedbackData = {
    locationNum: activeLocation,
    variables: [
      { id: 'location_num', value: activeLocation },
      { id: 'case_contact_guid', value: userGUID ?? '' },
      { id: 'short_description', value: 'Feedback on the Support App' },
      {
        id: 'summary',
        value: feedbackComment,
      },
    ],
    formTitle: 'Support Products Feedback',
  };

  const clearValues = () => {
    setFeedbackError(false);
    setFeedbackSuccess(false);
    setFeedbackComment('');
  };

  const handleClose = () => {
    onClose();
    clearValues();
  };

  const handleCancel = () => {
    onCancel && onCancel();
    clearValues();
  };

  useEffect(() => {
    if (!redirectToHelpOnline || !feedbackSuccess) return;

    setTimeout(() => {
      onClose();
      setFeedbackComment('');
      setFeedbackSuccess(false);
      redirectToHelpOnline();
    }, 2000);
  }, [redirectToHelpOnline, feedbackSuccess]);

  useEffect(() => {
    if (!show) return;
    callEvent('view feedback modal', {
      locationNum: activeLocation,
      screenName,
    });
  }, [show]);

  useEffect(() => {
    if (!submitFeedbackSuccess) return;
    setFeedbackSuccess(true);
  }, [submitFeedbackSuccess]);

  useEffect(() => {
    if (!submitFeedbackError) return;
    setFeedbackError(true);
  }, [submitFeedbackError]);

  return (
    <Modal
      show={show}
      onClose={onCancel ? handleCancel : handleClose}
      className='align-top with-top-margin'
      disableOverlayClick
      data-testid='feedback-modal'
    >
      {feedbackError && (
        <MessageBlockWrapper>
          <ErrorMessageBlock
            severity='error'
            title={
              !isExtraSmallScreen
                ? 'Failed to submit feedback, please try again.'
                : 'Failed to submit, please try again.'
            }
          >
            <></>
          </ErrorMessageBlock>
        </MessageBlockWrapper>
      )}
      <WebsiteFeedbackModalHeader
        feedbackSuccess={feedbackSuccess}
        isExtraSmallScreen={isExtraSmallScreen}
        title={title}
      />
      <WebsiteFeedbackModalBody
        feedbackSuccess={feedbackSuccess}
        submitFeedbackLoading={submitFeedbackLoading}
        isExtraSmallScreen={isExtraSmallScreen}
        setFeedbackComment={setFeedbackComment}
        feedbackComment={feedbackComment}
        inputPlaceholder={inputPlaceholder}
        redirectToHelpOnline={redirectToHelpOnline}
        feedbackTicketID={submitFeedbackResponseData?.id}
        onClose={handleClose}
        subTitle={subTitle}
        screenName={screenName}
        submitFeedback={() => submitFeedback(submitFeedbackData)}
        setFeedbackSuccess={() => setFeedbackSuccess(true)}
        hideTicketButton={hideTicketButton}
        closeButtonText={closeButtonText}
        whatsNewFeedback={whatsNewFeedback}
        featureFlowFeedback={featureFlowFeedback}
      />
    </Modal>
  );
};

export default WebsiteFeedbackModal;
