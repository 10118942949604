import { useOktaAuth } from '@okta/okta-react';
import { resetNamedUser } from '../utils/Airship/AirshipUtilities';
import { callEvent } from '../utils/Amplitude/AmplitudeUtilities';
import { useLocationStore } from '../stores/locationStore';
import { useContext } from 'react';
import { UserContext } from './UserContext';
import { UserContextType } from '../@types/user';

export const useSignout = () => {
  const { activeLocation } = useLocationStore();
  const { userState } = useContext(UserContext) as UserContextType;
  const { oktaAuth } = useOktaAuth();

  const handleLogout = (delayTime?: number) => {
    if (userState.sessionStart) {
      const sessionEnd = new Date().valueOf();
      const timeDiffInSeconds = (sessionEnd - userState.sessionStart) / 1000;
      const minutes = Math.floor(timeDiffInSeconds / 60);
      const seconds = Math.floor(timeDiffInSeconds % 60);
      callEvent('web logout', {
        locationNum: activeLocation,
        sessionLength: `${minutes} minutes ${seconds} seconds`,
      });
    }

    if (import.meta.env.VITE_APP_ENVIRONMENT !== 'prod') {
      resetNamedUser();
    }

    if (delayTime) {
      return setTimeout(() => {
        //clear out named user in Airship for sub prod envs
        oktaAuth.signOut();
      }, 1500);
    }

    return oktaAuth.signOut();
  };

  return { handleLogout };
};
