import { FC } from 'react';
import { Button } from '@cfa/react-components';
import { themeSizes } from '../../../styles/globalStyleVars';

interface Props {
  onClick?: () => void;
}

export const AffectsMeButton: FC<Props> = ({ onClick }) => (
  <Button
    variant='outlined'
    size='sm'
    style={{
      padding: `1px ${themeSizes.md}`,
      backgroundColor: 'white',
    }}
    onClick={onClick}
  >
    This Affects My Restaurant
  </Button>
);
