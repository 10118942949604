import { createContext, FC, ReactNode, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react/';
import { UserInterface, UserContextType, perms } from '../@types/user';
import { init } from '@amplitude/analytics-browser';
import { useLocationStore } from '../stores/locationStore';
import { getMobileOS } from '../utils/platform/getMobileOS';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { setUserAvatar } from './setUserAvatar';
import { setAmplitudeUserProps } from './setAmplitudeUserProps';
import { v4 as uuidv4 } from 'uuid';

export const UserContext = createContext<Nullable<UserContextType>>(null);

interface Props {
  children: ReactNode;
}

const UserProvider: FC<Props> = ({ children }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const ldClient = useLDClient();
  const flags = useFlags();
  const [userState, setUserState] = useState<UserInterface>({
    userDetails: null,
    isLoggedIn: false,
    idToken: null,
    base64IdToken: null,
    mobileOS: undefined,
    isStaffOrContractor: false,
    isOperator: false,
    isAdmin: false,
    sessionStart: null,
  });
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [logoutClicked, setLogoutClicked] = useState<boolean>(false);
  const [isOnboarded, setIsOnboarded] = useState<boolean>(false);
  const [isSessionTimeout, setIsSessionTimeout] = useState<boolean>(false);
  const [userAvatarPath, setUserAvatarPath] = useState<string>();
  const {
    setActiveLocation,
    setLocationAssigned,
    setUserLocations,
    setLocationDetailsLoading,
  } = useLocationStore();

  const getStoredLocation = () => {
    const item = localStorage.getItem('location-storage');
    const parsedItem = item ? JSON.parse(item) : null;
    if (!parsedItem || parsedItem.state.activeLocation === '') {
      return null;
    }
    return parsedItem.state.activeLocation;
  };

  function checkAdminPermissions(perms: perms) {
    return !!perms.CFAHELP_REACT?.ADMIN?.includes('00000');
  }

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserState({
        userDetails: null,
        isLoggedIn: false,
        idToken: null,
        base64IdToken: null,
        mobileOS: undefined,
        isOperator: false,
        isStaffOrContractor: false,
        isAdmin: false,
        sessionStart: null,
      });
    } else {
      authState.idToken &&
        authState.accessToken &&
        setUserState({
          userDetails: {
            display_name:
              authState.accessToken.claims.nickname &&
              authState.accessToken.claims.nickname !== ''
                ? authState.accessToken.claims.nickname
                : authState.idToken.claims.firstName,
            display_last_name:
              authState.accessToken.claims.family_name &&
              authState.accessToken.claims.family_name !== ''
                ? authState.accessToken.claims.family_name
                : authState.idToken.claims.lastName,
            ...authState.idToken.claims,
          },
          isLoggedIn: true,
          idToken: authState.idToken.idToken,
          base64IdToken: window.btoa(`{"Authorization":"${
            authState.idToken.idToken
          }",
          "host":"${import.meta.env.VITE_ESR_BASE_URL}"
          }`),
          mobileOS: getMobileOS(),
          isOperator: authState.idToken.claims.userType === 'Operator',
          isStaffOrContractor:
            authState.idToken.claims.userType === 'Staff' ||
            authState.idToken.claims.userType === 'Contractor',
          isAdmin: checkAdminPermissions(
            authState.accessToken.claims['cfa_perms'] as perms
          ),
          sessionStart: new Date().valueOf(),
        });

      //initializes amplitude
      const AMPLITUDE_TOKEN = import.meta.env.VITE_AMPLITUDE_TOKEN;
      //setting the defaultTracking to all false. we can change as needed down the road
      init(AMPLITUDE_TOKEN, authState?.idToken?.claims['cfa-guid'].toString(), {
        autocapture: {
          pageViews: false,
          formInteractions: false,
          fileDownloads: false,
          attribution: true,
        },
      });
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    if (!userState.isLoggedIn) return;
    const storedLocation = getStoredLocation();

    setUserAvatar(
      userState.userDetails.cfa_aud,
      userState.userDetails['cfa-guid'],
      userState.userDetails.employeeNumber
    ).then(res => setUserAvatarPath(res));

    setAmplitudeUserProps(userState.userDetails.cfa_aud, { ...flags });

    if (userState.userDetails?.['cfa-guid']) {
      ldClient?.identify({
        kind: 'multi',
        user: {
          key: userState.userDetails['cfa-guid'],
          name: userState.userDetails.name,
          version: __APP_VERSION__,
          audience: userState.userDetails.cfa_aud,
          locations: userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN,
        },
        ld_application: {
          key: uuidv4(),
          name: 'Chick-fil-A Support',
          version: __APP_VERSION__,
          id: import.meta.env.VITE_APP_URL,
        },
      });
    }

    if (userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN) {
      if (userState.idToken && userState?.isStaffOrContractor) {
        setActiveLocation(
          storedLocation ??
            userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN[0]
        );
        setLocationAssigned(true);
        return;
      }
      storedLocation &&
      userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN.includes(
        storedLocation
      )
        ? setActiveLocation(storedLocation)
        : setActiveLocation(
            userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN[0]
          );
      if (userState.idToken) {
        setUserLocations(
          userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN,
          userState.idToken
        );
        setLocationDetailsLoading(true);
      }
      if (
        userState.userDetails?.cfa_aud == 'OPERATOR_AUDIENCE' &&
        userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN?.length === 1 &&
        userState.userDetails?.cfa_perms.CFAHELP_REACT.LOGIN[0] == '00000'
      ) {
        return;
      }
      setLocationAssigned(true);
    }
  }, [userState.isLoggedIn]);

  return (
    <UserContext.Provider
      value={{
        userState,
        setUserState,
        isFormDirty,
        setIsFormDirty,
        setLogoutClicked,
        logoutClicked,
        setIsOnboarded,
        isOnboarded,
        isSessionTimeout,
        setIsSessionTimeout,
        userAvatarPath,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
