import { IconExternalLink } from '@tabler/icons-react';
import { Button } from '@cfa/react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import { themeSizes } from '../../../styles/globalStyleVars';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';

interface Props {
  alert: AlertInterface;
  activeLocation: string;
}

const RealTimeUpdateLink = styled(Button)`
  display: flex;
  justify-content: left;
  margin-bottom: ${themeSizes.xs};
  padding: 0;
  text-decoration: none !important;
`;

export const RealTimeUpdate: FC<Props> = ({ alert, activeLocation }) => {
  return (
    <RealTimeUpdateLink
      variant='text'
      onClick={() =>
        callEvent('launch real time updates for alert', {
          locationNum: activeLocation,
          'alert id': alert?.id,
          'alert title': alert.title,
        })
      }
      color='secondary'
      href={alert.outagePageURL}
      target='_blank'
    >
      <IconExternalLink size={20} style={{ marginRight: themeSizes.sm }} />
      See Real-time Updates
    </RealTimeUpdateLink>
  );
};
