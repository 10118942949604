export const corpLocationData = {
  id: '',
  number: '00000',
  name: 'CFA Corporate',
  supportDeskPhoneNumber: '',
  siteProperties: {
    lat: 0,
    long: 0,
    countryCode: '',
  },
  operator: {
    fullName: '',
    photoURL: '',
    email: '',
  },
  contacts: [
    {
      id: '',
      guid: '',
      fullName: '',
      firstName: '',
      lastName: '',
      audience: '',
      photoURL: '',
    },
  ],
};
