import { Identify, identify } from '@amplitude/analytics-browser';
import { LDFlagSet } from 'launchdarkly-react-client-sdk';

// Known User Types
// OPERATOR_AUDIENCE,
// STAFF_AUDIENCE,
// TEAM_MBR_AUDIENCE,
// CONTRACTOR_AUDIENCE

export function formatAudience(aud: string) {
  return aud
    .replace(/_/g, ' ')
    .replaceAll(
      /\S*/g,
      (word: string) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
    );
}

export function setAmplitudeUserProps(audience: string, flags: LDFlagSet) {
  if (!audience) return;
  const identifyUser = new Identify();
  identifyUser.set('audience', formatAudience(audience));
  for (const [key, value] of Object.entries(flags)) {
    identifyUser.set(`ff_${key}`, value);
  }
  identify(identifyUser);
}
