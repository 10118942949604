import { FC, Dispatch, SetStateAction } from 'react';
import { Drawer } from '@cfa/react-components';
import styled from 'styled-components';
import { themeSizes } from '../../../styles/globalStyleVars';
import { AlertsDrawerContent } from './AlertsDrawerContent';
import { ChildCaseDrawerContent } from './ChildCaseDrawerContent';
import { AlertInterface } from '../../../interfaces/AlertInterface';

interface Props {
  alertsDrawerOpen: boolean;
  setAlertsDrawerOpen: Dispatch<SetStateAction<boolean>>;
  alertsError: boolean;
  alertsLoading: boolean;
  refetchAlerts: () => void;
  setAlertChildCaseOpen: (val: AlertInterface | undefined) => void;
  alertChildCaseOpen: AlertInterface | undefined;
}

const AlertsDrawerContainer = styled(Drawer)`
  > .cfa-surface {
    overflow-y: hidden;
    z-index: 3000;
  }

  > div {
    padding: ${themeSizes.xl} ${themeSizes.lg} 64px ${themeSizes.lg};
  }
`;

export const AlertsDrawer: FC<Props> = ({
  alertsDrawerOpen,
  setAlertsDrawerOpen,
  alertsError,
  alertsLoading,
  refetchAlerts,
  setAlertChildCaseOpen,
  alertChildCaseOpen,
}) => {
  const closeAlertsDrawer = () => {
    setAlertsDrawerOpen(false);
    setAlertChildCaseOpen(undefined);
  };

  return (
    <AlertsDrawerContainer
      open={alertsDrawerOpen}
      onClose={closeAlertsDrawer}
      width='wide'
      content={
        alertChildCaseOpen ? (
          <ChildCaseDrawerContent
            closeAlertsDrawer={closeAlertsDrawer}
            goBack={() => setAlertChildCaseOpen(undefined)}
            alertChildCaseOpen={alertChildCaseOpen}
          />
        ) : (
          <AlertsDrawerContent
            closeAlertsDrawer={() => setAlertsDrawerOpen(false)}
            alertsDrawerOpen={alertsDrawerOpen}
            alertsError={alertsError}
            alertsLoading={alertsLoading}
            refetchAlerts={refetchAlerts}
            setAlertChildCaseOpen={setAlertChildCaseOpen}
          />
        )
      }
    />
  );
};
