import { FC, useState, useContext, lazy } from 'react';
import {
  Typography,
  Container,
  Row,
  Col,
  Button,
  Link,
  useMediaQuery,
  useBreakpoints,
} from '@cfa/react-components';
import styled from 'styled-components';
import {
  mediaQueries,
  themeColors,
  themeSizes,
} from '../../styles/globalStyleVars';
import { UserContext } from '../../user/UserContext';
import { UserContextType } from '../../@types/user';
import { useHistory } from 'react-router-dom';
import { IconMessage2Share, IconDeviceMobile } from '@tabler/icons-react';
import SWLogo from '../../assets/sw-logo.svg';
import { mobileAppLinks } from '../../utils/platform/mobileAppLinks';
import { callEvent } from '../../utils/Amplitude/AmplitudeUtilities';
import WebsiteFeedbackModal from '../Global/Modals/WebsiteFeedbackModal/WebsiteFeedbackModal';
import { useLocationStore } from '../../stores/locationStore';

const DownloadAppAndScanModal = lazy(
  () => import('../Global/Modals/DownloadAndScanModal/DownloadAppAndScanModal')
);

const FooterWrapper = styled.div`
  background-color: ${themeColors.notificationBackgroundColor};
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
`;

const FooterContainer = styled(Container)`
  padding-top: ${themeSizes.lg};
  padding-bottom: ${themeSizes.lg};
  ${mediaQueries.sm_up} {
    padding-top: ${themeSizes.xl};
    padding-bottom: ${themeSizes.xl};
  }
`;

const FooterContentRow = styled(Row)`
  justify-content: space-between;
`;

const LogoWithText = styled.span`
  display: flex;
  align-items: center;
  margin-bottom: ${themeSizes.sm};
  img {
    margin-right: ${themeSizes.sm};
  }
  &.support-app {
    padding-bottom: ${themeSizes.sm};
  }
`;

const IconWrapper = styled.span`
  font-size: ${themeSizes.lg};
  color: ${themeColors.colorGray_6};
  margin-right: ${themeSizes.md};
  display: flex;
`;

const FooterHeading = styled(Typography)`
  color: ${themeColors.colorGray_6};

  &.responsive-bottom-margin {
    margin-bottom: ${themeSizes.md};

    ${mediaQueries.sm_up} {
      margin-bottom: ${themeSizes.sm};
    }

    ${mediaQueries.md_up} {
      margin-bottom: ${themeSizes.md};
    }
  }
`;

const FooterText = styled(Typography)`
  &.underline {
    text-decoration: underline;
  }
  &.with-bottom-margin {
    margin-bottom: ${themeSizes.sm};
  }
`;

const OpenModalButton = styled(Button)<{ $isLastButton: boolean }>`
  background-color: ${themeColors.primaryWhite} !important;
  margin-top: ${themeSizes.md};
  margin-bottom: ${props => (props.$isLastButton ? '0' : `${themeSizes.lg}`)};
  ${mediaQueries.sm_up} {
    margin-bottom: 0px;
  }
`;

const FooterBottomRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  padding-top: ${themeSizes.xl};
  ${mediaQueries.sm_up} {
    padding-top: 80px;
  }
`;

const FooterIcon = styled.img`
  &.chick-fil-a-support {
    width: ${themeSizes.lg};
    height: auto;
  }
  &.support-circle {
    width: ${themeSizes.xxl};
    height: auto;
  }
`;

export const Footer: FC = () => {
  const { activeLocation } = useLocationStore();
  const breakpoints = useBreakpoints();
  const isExtraSmallScreen = useMediaQuery(breakpoints.down('sm'));
  //generates current year for footer
  const currentYear = new Date().getFullYear();
  const [showDownloadAppModal, setShowDownloadAppModal] =
    useState<boolean>(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false);
  const { userState } = useContext(UserContext) as UserContextType;
  const history = useHistory();

  const openDownloadApp = () => {
    callEvent('view download app', {
      deviceType: userState.mobileOS ?? 'Desktop/Laptop',
    });

    if (userState.mobileOS === 'Android') {
      return window.open(mobileAppLinks['Android'], '_blank');
    }
    if (userState.mobileOS === 'iOS') {
      return window.open(mobileAppLinks['iOS'], '_blank');
    }
    return setShowDownloadAppModal(true);
  };

  const FeebackCol: FC<{ isLastButton?: boolean }> = ({ isLastButton }) => (
    <Col xl={4} md={4} sm={4} xs={12}>
      <LogoWithText>
        <IconWrapper>
          <IconMessage2Share />
        </IconWrapper>
        <FooterHeading variant='h4'>
          We&apos;d love to hear what you think.
        </FooterHeading>
      </LogoWithText>
      <Typography>
        What do you think of the new website?
        <br /> All feedback will help us better support you.
      </Typography>
      <OpenModalButton
        onClick={() => setShowFeedbackModal(true)}
        size='sm'
        variant='outlined'
        data-testid='footer-app-btn-feedback'
        $isLastButton={isLastButton}
      >
        Submit Feedback
      </OpenModalButton>
    </Col>
  );

  return (
    <FooterWrapper data-testid='footer-wrapper'>
      <FooterContainer className='container' fluid={true}>
        <FooterContentRow>
          <Col xl={4} md={4} sm={4} xs={12}>
            <LogoWithText>
              <IconWrapper>
                <IconDeviceMobile />
              </IconWrapper>
              <FooterHeading variant='h4'>
                Download the CFA Support App
              </FooterHeading>
            </LogoWithText>
            <Typography>
              Get updates on your tickets, reply to and manage tickets, upload
              files and more from your phone.
            </Typography>
            <OpenModalButton
              onClick={openDownloadApp}
              size='sm'
              variant='outlined'
              data-testid='footer-app-btn-app'
            >
              Download App
            </OpenModalButton>
          </Col>
          {!isExtraSmallScreen && <FeebackCol />}
          <Col xl={3} md={3} sm={4} xs={12}>
            <FooterHeading variant='h4' className='responsive-bottom-margin'>
              Contact Support Now (24/6)
            </FooterHeading>
            <FooterText className='underline'>
              For critical issues call
            </FooterText>
            <FooterText className='with-bottom-margin'>
              Call&nbsp;
              <Link
                variant='standalone'
                style={{ color: themeColors.colorGray_6, paddingLeft: '0px' }}
                data-testid='footer-phone-link'
                onClick={() => {
                  window.open('tel:1-800-232-2677', '_self');
                  callEvent('call button on get help', {
                    locationNum: activeLocation,
                  });
                }}
              >
                1-800-232-2677
              </Link>
            </FooterText>
            <FooterText className='underline' gutterBottom>
              For non critical issues
            </FooterText>
            <Button
              onClick={() => history.push('/forms')}
              size='sm'
              data-testid='footer-open-ticket-btn'
              style={{ marginBottom: isExtraSmallScreen ? themeSizes.xl : 0 }}
            >
              Open a Ticket
            </Button>
          </Col>
          {isExtraSmallScreen && <FeebackCol isLastButton />}
        </FooterContentRow>
        <FooterBottomRow>
          <Col xl={4} sm={6}>
            <LogoWithText>
              <FooterIcon
                src={SWLogo}
                className='chick-fil-a-support'
                alt='Chick-fil-A Support Logo'
              />
              <Typography fontWeight='bold' color='primary'>
                Chick-fil-A Support
              </Typography>
            </LogoWithText>
          </Col>
          <Col xl={3} md={4} sm={6}>
            <Typography variant='caption1'>
              ©{currentYear} Chick-fil-A, Inc. All rights reserved
            </Typography>
          </Col>
        </FooterBottomRow>
      </FooterContainer>
      <DownloadAppAndScanModal
        onClose={() => setShowDownloadAppModal(false)}
        show={showDownloadAppModal}
        title='Download the CFA Support App'
        subTitle='Scan with your smartphone camera to download.'
        modalType='download'
        deviceType={userState.mobileOS}
      />
      <WebsiteFeedbackModal
        show={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
        title={'Tell us your thoughts about the\nnew website.'}
        subTitle={
          <>
            Please tell us what&apos;s on your mind. The more detail you share
            the better we can serve you.&nbsp;
            <b>We&apos;ll create a ticket to submit your feedback.</b>
          </>
        }
        screenName='Footer'
        userGUID={userState.userDetails?.['cfa-guid']}
      />
    </FooterWrapper>
  );
};
