enum breakpointSizes {
  xSmallMin = '0px',
  // Small tablets and large smartphones-{landscape view}
  smallMin = '576px',
  // Small tablets-{portrait view}
  mediumMin = '768px',
  // Tablets and small desktops
  largeMin = '992px',
  // Large tablets and desktops
  xLargeMin = '1200px',
  // Extra-Large tablets and desktops
  xxLargeMin = '1400px',
}

const mediaQueries = {
  sm_up: `@media (min-width: ${breakpointSizes.smallMin})`,
  md_up: `@media (min-width: ${breakpointSizes.mediumMin})`,
  lg_up: `@media (min-width: ${breakpointSizes.largeMin})`,
  xl_up: `@media (min-width: ${breakpointSizes.xLargeMin})`,
  xxl_up: `@media (min-width: ${breakpointSizes.xxLargeMin})`,
  sm_only: `@media (min-width: ${breakpointSizes.smallMin}) and (max-width: ${breakpointSizes.mediumMin})`,
};

enum themeSizes {
  xs = '4px',
  sm = '8px',
  md = '16px',
  lg = '24px',
  xl = '32px',
  xxl = '48px',
  formFieldBottomMargin = '24px',
}

enum themeColors {
  primaryRed = '#dd0031',
  primaryWhite = '#fff',

  // Secondary Pallette
  secondaryBlue = '#004f71',
  secondaryTeal = '#3eb1c8',

  // Tertiary Palette
  tertiaryYellow = '#ffb549',
  tertiaryLightYellow = '#f5e1a4',
  tertiaryTan = '#f5e3cc',
  tertiaryPink = '#f8c1b8',
  tertiaryPeach = '#fa9370',
  tertiaryOrange = '#e35205',
  tertiaryGreen = '#249e6b',
  tertiaryDarkGreen = '#00635b',
  tertiaryPurple = '#994878',
  tertiaryDarkPurple = '#5d3754',

  // Hover / Pressed States
  primaryRedHover = '#b00027',
  primaryRedPressed = '#84001d',
  secondaryBlueHover = '#003e59',
  secondaryBluePressed = '#002f43',
  destructiveRedHover = '#7e031f',
  destructiveRedPressed = '#7e031f',

  // Semantic Colors
  errorColor = '#b6072f',
  warningColor = '#fbc111',
  successColor = '#077e4c',
  informationColor = '#006b9a',

  // Grayscale
  colorGray_1 = '#ecedee',
  colorGray_2 = '#cccfd0',
  colorGray_3 = '#a7acaf',
  colorGray_4 = '#93989b',
  colorGray_5 = '#80878c',
  colorGray_6 = '#5b6770',
  colorGray_7 = '#37424a',
  colorGray_8 = '#1e272f',

  // CUSTOM colors
  heroBackgroundColor = '#fcf6ef',
  notificationBackgroundColor = '#f7f8f8',
  colorBlack = '#000',
  fieldErrorBackgroundColor = '#fcf4f5',
  fieldDisabledBackgroundColor = '#f8f8f8',
  fieldFocusColor = 'rgb(62 177 200 / 0.4)',
  CTAMenuBackgroundColor = '#f7f9fa',
  searchButtonBackground = '#ccdce3',
}

const kbColorOptions = {
  '#808080': themeColors.colorGray_6,
  '#999999': themeColors.colorGray_6,
  '#95a5a6': themeColors.colorGray_6,
  '#7e8c8d': themeColors.colorGray_6,
  '#ff0000': themeColors.primaryRed,
};

export {
  breakpointSizes,
  mediaQueries,
  themeColors,
  themeSizes,
  kbColorOptions,
};
