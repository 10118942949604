import { FC } from 'react';
import { LoadingIndicator } from '@cfa/react-components';
import { themeColors } from '../../../styles/globalStyleVars';
import styled from 'styled-components';
import { AlertsDrawerEmptyState } from './AlertsDrawerEmptyState';
import FetchError from '../FetchError';

interface Props {
  childCasesLoading: boolean;
  loadingError: boolean;
  minorCasesLoading: boolean;
  handleErrorRefetch: () => void;
}

const LoadingOverlayContainer = styled.div<{ $showOverlay: boolean }>`
  display: ${props => (props.$showOverlay ? 'flex' : 'none')};
  position: absolute;
  background-color: ${themeColors.primaryWhite};
  height: 100%;
  width: 100%;
  justify-content: center;
  align-self: center;
`;

export const LoadingOverlay: FC<Props> = ({
  childCasesLoading,
  minorCasesLoading,
  loadingError,
  handleErrorRefetch,
}) => (
  <LoadingOverlayContainer
    $showOverlay={childCasesLoading || loadingError || minorCasesLoading}
  >
    {childCasesLoading ? (
      <AlertsDrawerEmptyState heading='Working on that...' showLoadingSpinner />
    ) : (
      <>
        {loadingError && (
          <div>
            <FetchError
              btnClick={handleErrorRefetch}
              errorType='modal-fetch'
              page='alerts-drawer'
            />
          </div>
        )}
        {minorCasesLoading && (
          <div style={{ display: 'block' }}>
            <LoadingIndicator
              size='md'
              variant='page'
              style={{ margin: '3rem auto' }}
            />
          </div>
        )}
      </>
    )}
  </LoadingOverlayContainer>
);
