import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App/App';
import reportWebVitals from './reportWebVitals';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const root = createRoot(document.getElementById('root') as HTMLElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_ID,
    // Initializes with an anonymous context when no context is passed in
    options: {
      // pass in initialization options here
      streaming: false,
      // not subscribing to live updates (react SDK default is true)
    },
    reactOptions: {
      // turn off camel case flag keys
      useCamelCaseFlagKeys: false,
    },
  });

  root.render(
    <LDProvider>
      <App />
    </LDProvider>
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
